import { useState } from "react";

import { useCloudCredentialsV2Query } from "../../graphql";
import { Button } from "../../ui/button";
import { PlusIcon } from "../../ui/icons";
import { Select } from "../../ui/select";
import { CreateCredential } from "./create";

export const SelectCredential = ({ provider, value, onChange }) => {
  const { data, isLoading, refetch } = useCloudCredentialsV2Query({ provider });

  const options = data?.getCloudCredentials.map((cc) => ({
    label: cc.name,
    value: cc.id,
  }));

  const [isCreating, setCreating] = useState<boolean>();

  return (
    <>
      {isCreating && <CreateCredential defaultProvider={provider} onClose={() => setCreating(false)} />}

      <Select
        isLoading={isLoading}
        options={options}
        placeholder={"Select credentials..."}
        reload={refetch}
        value={value}
        onChange={(selected) => onChange(selected.value)}
      />

      <Button iconBefore={<PlusIcon />} size="small" sx={{ mt: 2 }} variant="secondary" onClick={() => setCreating(true)}>
        Create new
      </Button>
    </>
  );
};
