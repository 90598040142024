import { FC, ReactNode, MouseEventHandler, useRef } from "react";

import { Button as ThemedButton, ThemeUIStyleObject, Box } from "theme-ui";

import { Tooltip } from "src/ui/tooltip";

import { Spinner } from "../loading";

export type ButtonProps = {
  children?: ReactNode;
  label?: string;
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
  sx?: ThemeUIStyleObject;
  variant?: string;
  disabled?: boolean;
  iconBefore?: ReactNode;
  iconAfter?: ReactNode;
  href?: string;
  size?: "small" | "large";
  propagate?: boolean;
  tooltip?: string;
  type?: "submit" | "button";
  form?: string;
};

export const Button: FC<ButtonProps> = ({
  children,
  variant = "primary",
  label,
  loading = false,
  disabled = false,
  onClick,
  sx,
  iconBefore,
  iconAfter,
  size,
  onMouseEnter,
  onMouseLeave,
  propagate,
  tooltip,
  type = "button",
  form,
}) => {
  const ref = useRef<HTMLButtonElement | null>(null);

  return (
    <Tooltip disabled={!tooltip} text={tooltip ?? ""}>
      <ThemedButton
        ref={ref}
        disabled={disabled}
        form={form}
        sx={{
          py: size === "large" ? 3 : size === "small" ? "6px" : undefined,
          px: size === "large" ? 4 : undefined,
          fontSize: size === "large" ? 2 : undefined,
          width: loading ? ref?.current?.offsetWidth : undefined,
          height: size === "large" ? "48px" : "32px",
          pointerEvents: loading ? "none" : undefined,
          bg: loading && variant === "primary" ? "black" : undefined,
          ...sx,
        }}
        type={type}
        variant={variant}
        onClick={(event) => {
          if (!propagate) {
            event.stopPropagation();
          }
          if (onClick) {
            onClick(event);
          }
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {iconBefore && !loading && <Box sx={{ mr: size === "small" ? 1 : 2, flexShrink: 0 }}>{iconBefore}</Box>}
        {loading ? (
          <Spinner
            color={
              variant === "secondary" || variant === "icon" || variant === "soft" || variant === "purple" ? "forest" : "white"
            }
            size={16}
          />
        ) : (
          children || label
        )}
        {iconAfter && !loading && <Box sx={{ ml: size === "small" ? 1 : 2, flexShrink: 0 }}>{iconAfter}</Box>}
      </ThemedButton>
    </Tooltip>
  );
};
