import { FC } from "react";

import * as Yup from "yup";

import { MappingsField } from "src/components/destinations/mappings-field";
import { useDestinationForm } from "src/contexts/destination-form-context";
import { useFacebookAdAccountsQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Section } from "src/ui/section";
import { CreatableSelect } from "src/ui/select";
import { COMMON_SCHEMAS, useDestination } from "src/utils/destinations";

import { PIIHashingField } from "../pii-hashing-field";

export const validation = Yup.object().shape({
  pageId: Yup.string().notRequired(),
  mappings: COMMON_SCHEMAS.mappings,
  disablePIIHashing: Yup.boolean().notRequired(),
  shareAccountIds: Yup.array().of(Yup.string()).notRequired(),
});

const OPTIONS = [
  { label: "Email", value: "EMAIL" },
  { label: "Phone", value: "PHONE" },
  { label: "Gender", value: "GEN" },
  { label: "First Name", value: "FN" },
  { label: "First Initial", value: "FI" },
  { label: "Last Name", value: "LN" },
  { label: "Date of Birth (Year)", value: "DOBY" },
  { label: "Date of Birth (Month)", value: "DOBM" },
  { label: "Date of Birth (Day)", value: "DOBD" },
  { label: "State", value: "ST" },
  { label: "City", value: "CT" },
  { label: "Zip Code", value: "ZIP" },
  { label: "Country", value: "COUNTRY" },
  { label: "Mobile ID", value: "MADID" },
  { label: "External ID", value: "EXTERN_ID" },
  { label: "Page-Scoped ID", value: "PAGEUID" },
];

export const FacebookForm: FC = () => {
  const { config, setConfig, model, destination } = useDestinationForm();

  const hasPageId = !!config?.mappings?.find((m) => m.to === "PAGEUID");

  const {
    data: { destination: fullDestination },
    loading: destinationLoading,
  } = useDestination(destination?.id, { pause: !destination?.id });

  const destConfig = fullDestination?.config;

  const { data: accountsData, isLoading: accountsLoading } = useFacebookAdAccountsQuery({ destId: String(destination?.id) });

  const accounts = accountsData?.facebookListAdAccounts?.accounts?.filter((a) => a.id !== destConfig?.adAccountId);
  const accountOptions = accounts?.map((a) => ({ label: a.name, value: a.id })) || [];

  return (
    <>
      <Section>
        <MappingsField options={OPTIONS} />
      </Section>

      {hasPageId && (
        <Section>
          <Field label="What is the Facebook Page ID?">
            <Input
              defaultValue={config?.pageId}
              placeholder={"Page ID"}
              sx={{ width: "180px" }}
              onChange={(pageId) => setConfig({ ...config, pageId })}
            />
          </Field>
        </Section>
      )}

      <Section>
        <Field
          optional
          description="If this name is not specified, Hightouch defaults to using the model name to create the audience."
          label="Would you like to use a custom audience name?"
          size="large"
        >
          <Input
            defaultValue={config?.customAudienceName}
            placeholder={model?.name}
            sx={{ width: "240px" }}
            onChange={(customAudienceName) => setConfig({ ...config, customAudienceName })}
          />
        </Field>
      </Section>

      <PIIHashingField />

      <Section>
        <Field
          optional
          description="Make sure that the ad account selected has an existing partner relationship with the primary ad account."
          label="Would you like to share your audience with another ad account?"
          size="large"
        >
          <CreatableSelect
            isClearable
            isMulti
            isLoading={accountsLoading || destinationLoading}
            options={accountOptions}
            placeholder="Select an ad account..."
            value={config?.shareAccountIds}
            width="300px"
            onChange={(v) => setConfig({ ...config, shareAccountIds: v?.map((v) => v?.value) })}
          />
        </Field>
      </Section>
    </>
  );
};

export default {
  form: FacebookForm,
  validation,
};
