import { FC, ReactNode, MouseEventHandler, useMemo } from "react";

import { noop } from "lodash";
import { ThemeUIStyleObject, Text } from "theme-ui";

import { Row, Column, Box } from "src/ui/box";
import { DotsIcon } from "src/ui/icons";
import { IconProps } from "src/ui/icons/icon";
import { Link } from "src/ui/link";
import { Popout } from "src/ui/popout";
import { Placement } from "src/ui/popout/popout";

export type MenuOption = {
  label?: ReactNode;
  icon?: FC<IconProps>;
  onClick?: () => void;
  sx?: ThemeUIStyleObject;
  divider?: "top" | "bottom";
  disabled?: boolean;
  variant?: string;
  title?: string;
  description?: string;
  link?: string;
  newTab?: boolean;
  render?: FC;
  stickToTop?: boolean;
};

export interface MenuProps {
  contentSx?: ThemeUIStyleObject;
  disabled?: boolean;
  options: MenuOption[];
  children?: ReactNode;
  sx?: ThemeUIStyleObject;
  width?: string;
  footer?: ReactNode;
  header?: ReactNode;
  placement?: Placement;
  offset?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
  portal?: boolean;
  strategy?: "absolute" | "fixed";
  loading?: boolean;
}

export const Menu: FC<Readonly<MenuProps>> = ({
  children,
  contentSx = {},
  disabled = false,
  options,
  footer,
  header,
  width,
  placement = "bottom-start",
  offset,
  sx = {},
  onClick,
  portal,
  strategy = "absolute",
}) => {
  const style = useMemo(
    () => ({
      minWidth: "112px",
      backgroundColor: "white",
      width: width,
      ...contentSx,
    }),
    [contentSx, width],
  );

  const nonScrollOptions = options.filter(({ stickToTop }) => stickToTop);
  const scrolledOptions = options.filter(({ stickToTop }) => !stickToTop);

  return (
    <Popout
      content={({ close }) => (
        <>
          {header && <Row sx={{ p: 3, pb: 1 }}>{header}</Row>}
          {nonScrollOptions.length > 0 && (
            <Box sx={{ p: 1, pb: 0 }}>
              {nonScrollOptions.map((option, i) => (
                <Option
                  key={i}
                  {...option}
                  onClick={() => {
                    close();
                    if (option.onClick) {
                      option.onClick();
                    }
                  }}
                />
              ))}
            </Box>
          )}
          <Box sx={{ p: 1, overflowY: "auto" }}>
            {scrolledOptions.map((option, i) => (
              <Option
                key={i}
                {...option}
                onClick={() => {
                  close();
                  if (option.onClick) {
                    option.onClick();
                  }
                }}
              />
            ))}
          </Box>
          {footer && <Row sx={{ p: 3, pt: 0 }}>{footer}</Row>}
        </>
      )}
      contentSx={style}
      disabled={disabled}
      offset={offset}
      placement={placement}
      portal={portal}
      strategy={strategy}
      sx={sx}
      onClick={disabled ? noop : onClick}
    >
      {({ isOpen }) => {
        return children || <DotsIcon size={16} sx={{ color: isOpen ? "primary" : "base.4", p: 1 }} />;
      }}
    </Popout>
  );
};

const Option: FC<Readonly<MenuOption>> = ({
  variant,
  sx = {},
  title,
  divider,
  label,
  disabled,
  icon: Icon,
  onClick,
  description,
  newTab,
  link,
  render,
}) => {
  const element = (
    <Row
      sx={{
        alignItems: "center",
        p: 2,
        borderRadius: 1,
        color: variant === "danger" ? "red" : "black",
        cursor: disabled ? "not-allowed" : "pointer",
        transition: "100ms color background-color",
        svg: {
          transition: "100ms fill",
        },
        ":hover": {
          bg: disabled ? undefined : "gray.200",
          color: "black",
          svg: {
            fill: "grass",
          },
        },
        ...sx,
      }}
      onClick={() => {
        if (disabled) return;

        if (link && !newTab) {
          window.location.href = link;
        }
        if (onClick) {
          onClick();
        }
      }}
    >
      {render ? (
        render({})
      ) : (
        <>
          {divider === "top" ? <Box sx={{ borderTop: "small" }} /> : null}
          {Icon && <Icon color="forest" size={description ? 30 : 20} sx={{ mr: description ? 3 : "6px" }} />}
          <Column>
            <Text
              sx={{
                fontWeight: description ? "bold" : "semi",
                color: disabled ? "base.3" : variant === "danger" ? "red" : description ? "black" : "inherit",
              }}
            >
              {label}
            </Text>
            {description && <Text sx={{ color: "base.7" }}>{description}</Text>}
          </Column>
          {divider === "bottom" ? <Box sx={{ borderTop: "small" }} /> : null}
        </>
      )}
    </Row>
  );

  return (
    <>
      {divider === "top" ? <Box sx={{ borderTop: "small", my: 2 }} /> : null}
      {title ? (
        <Text sx={{ px: 2, py: "6px", fontSize: 0, fontWeight: "bold", textTransform: "uppercase", color: "dark.0" }}>
          {title}
        </Text>
      ) : null}
      {newTab ? (
        <Link newTab to={link}>
          {element}
        </Link>
      ) : (
        element
      )}
      {divider === "bottom" ? <Box sx={{ borderTop: "small", my: 2 }} /> : null}
    </>
  );
};
