import { useEffect, useState } from "react";

import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { Box, Button, Link } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { useFlags } from "launchdarkly-react-client-sdk";
import pluralize from "pluralize";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { Page } from "src/components/layout";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { Permission } from "src/components/permission";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  PriorityListsOrderBy,
  PriorityListsQuery,
  ResourcePermissionGrant,
  useDeletePriorityListMutation,
  usePriorityListsQuery,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { Heading } from "src/ui/heading";
import { SearchInput } from "src/ui/input";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useRowSelect } from "src/ui/table/use-row-select";
import { TextWithTooltip } from "src/ui/text";
import { SourceIcon, useSources } from "src/utils/sources";
import { openUrl } from "src/utils/urls";

enum SortKeys {
  Name = "name",
  ParentModel = "parent_model.name",
  UpdatedAt = "updated_at",
}

export const PriorityLists = () => {
  const { appPriorityLists } = useFlags();
  const navigate = useNavigate();
  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [search, setSearch] = useQueryState("search");

  const { selectedRows, onRowSelect } = useRowSelect();
  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<PriorityListsOrderBy>({
    defaultSortKey: "updated_at",
    limit: 50,
    sortOptions: Object.values(SortKeys),
  });

  const { hasPermission: userCanDelete } = useHasPermission([
    { resource: "audience", grants: [ResourcePermissionGrant.Delete] },
  ]);

  const deletePriorityListMutation = useDeletePriorityListMutation();

  const priorityListsQuery = usePriorityListsQuery({
    filters: search?.trim()
      ? {
          _or: [
            {
              name: { _ilike: `%${search?.trim()}%` },
            },
            {
              parent_model: { name: { _ilike: `%${search?.trim()}%` } },
            },
          ],
        }
      : undefined,
    limit,
    offset,
    orderBy,
  });

  // Get source information
  const { data: sources } = useSources();

  const priorityLists = priorityListsQuery.data?.priority_lists ?? [];
  const priorityListsCount = priorityListsQuery.data?.priority_lists_aggregate.aggregate?.count;

  const onRowClick = ({ id }, event) => openUrl(`/audiences/priority-lists/${id}`, navigate, event);

  const bulkDeletePriorityLists = async () => {
    if (userCanDelete) {
      try {
        const promises = selectedRows.map((id) => deletePriorityListMutation.mutateAsync({ id: id.toString() }));
        await Promise.all(promises);

        // Success toast is created in bulk deletion modal
        onRowSelect([]);
      } catch (error) {
        // Error toast is created in bulk deletion modal
        Sentry.captureException(error);
      }
    }
  };

  useEffect(() => {
    if (!appPriorityLists) {
      navigate("/audiences");
    }
  }, [appPriorityLists, navigate]);

  const columns: TableColumn[] = [
    {
      name: "Name",
      sortDirection: orderBy?.name,
      onClick: () => onSort(SortKeys.Name),
      cell: ({ name }: PriorityListsQuery["priority_lists"][0]) => <TextWithTooltip text={name}>{name}</TextWithTooltip>,
    },
    {
      name: "Parent Model",
      sortDirection: orderBy?.parent_model?.name,
      onClick: () => onSort(SortKeys.ParentModel),
      cell: ({ parent_model }: PriorityListsQuery["priority_lists"][0]) => {
        const source = sources.find(({ id }) => parent_model?.connection_id === id);

        return (
          <Box alignItems="center" display="flex" onClick={(event) => event.stopPropagation()}>
            <SourceIcon source={source} sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", mr: 2 }} />
            <Link as={RouterLink} href={`/audiences/setup/parent-models/${parent_model.id}`}>
              {parent_model?.name}
              <Box display="inline-block" height="16px" ml={1} width="16px">
                <ArrowTopRightOnSquareIcon />
              </Box>
            </Link>
          </Box>
        );
      },
    },
    {
      ...LastUpdatedColumn,
      sortDirection: orderBy?.updated_at,
      onClick: () => onSort(SortKeys.UpdatedAt),
    },
  ];

  return (
    <PermissionProvider permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Update] }]}>
      <Page
        crumbs={[
          { label: "Audiences", link: "/audiences" },
          { label: "Priority Lists", link: "/audiences/priority-lists" },
        ]}
        size="full"
      >
        <Box alignItems="center" display="flex" justifyContent="space-between" mb={8}>
          <Heading sx={{ mr: 2 }}>Priority Lists</Heading>
          <Box display="flex">
            {selectedRows?.length > 0 && (
              <Permission permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Delete] }]}>
                <Box mr={3}>
                  <Button variant="warning" onClick={() => setConfirmingDelete(true)}>
                    {pluralize("Delete priority list", selectedRows.length)}
                  </Button>
                </Box>
              </Permission>
            )}
            <Permission permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Create] }]}>
              <Button
                variant="primary"
                onClick={() => {
                  analytics.track("Add Priority List Button Clicked");
                  navigate(`/audiences/priority-lists/new`);
                }}
              >
                Add priority list
              </Button>
            </Permission>
          </Box>
        </Box>
        <Box mb={3}>
          <SearchInput
            placeholder="Search priority lists by name or parent model name..."
            value={search ?? ""}
            onChange={setSearch}
          />
        </Box>
        <Table
          columns={columns}
          data={priorityLists}
          error={Boolean(priorityListsQuery.error)}
          loading={priorityListsQuery.isLoading || priorityListsQuery.isRefetching}
          placeholder={{
            title: "No priority lists",
            body: "Add a priority list to get started",
            error: "Priority lists failed to load, please try again.",
          }}
          selectedRows={selectedRows}
          onRowClick={onRowClick}
          onSelect={onRowSelect}
        />
        <Pagination count={priorityListsCount} label="priority lists" page={page} rowsPerPage={limit} setPage={setPage} />

        <BulkDeleteConfirmationModal
          count={selectedRows.length}
          isOpen={confirmingDelete}
          label="priority list"
          loading={deletePriorityListMutation.isLoading}
          onClose={() => setConfirmingDelete(false)}
          onDelete={bulkDeletePriorityLists}
        />
      </Page>
    </PermissionProvider>
  );
};
