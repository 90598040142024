import { FC, useMemo, ReactNode } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { Column, Row } from "src/ui/box";

export enum CardSize {
  Small = "small",
  Large = "large",
}

export interface CardProps {
  children: ReactNode;
  size?: keyof CardSize;
  sx?: ThemeUIStyleObject;
  onClick?: () => void;
  clickable?: boolean;
  footer?: ReactNode;
}

const baseStyle: ThemeUIStyleObject = {
  bg: "white",
  border: "small",
  borderRadius: 4,
};

const clickableStyle: ThemeUIStyleObject = {
  ":hover": {
    borderColor: "grass",
    bg: "avocado",
  },
  cursor: "pointer",
};

const sizes: Record<CardSize, ThemeUIStyleObject> = {
  [CardSize.Large]: {
    p: 10,
  },
  [CardSize.Small]: {
    p: 6,
  },
};

export const Card: FC<Readonly<CardProps>> = ({ children, size = "large", sx = {}, onClick, footer, ...props }) => {
  const clickable = Boolean(onClick || props.clickable);

  const style = useMemo(
    () => ({
      ...baseStyle,

      ...sx,
      ...(clickable ? clickableStyle : {}),
    }),
    [size, sx, onClick],
  );

  return (
    <Column sx={style} onClick={onClick}>
      <Column sx={sizes[size]}>{children}</Column>
      {footer && <Row sx={{ borderTop: "small", ...sizes[size], py: 4 }}>{footer}</Row>}
    </Column>
  );
};
