import { FC } from "react";

import { Text } from "theme-ui";

import { Page } from "src/components/layout";
import { newIntercomMessage } from "src/lib/intercom";
import { Row, Column, Container } from "src/ui/box";
import { Button } from "src/ui/button";
import { AudienceIcon, CheckCircleIcon } from "src/ui/icons";

import { Logos } from "./demo-logos";

export const AudiencesDemo: FC = () => {
  return (
    <Page crumbs={[{ label: "Audiences" }]} size="full" sx={{ px: 0 }}>
      <Row
        sx={{
          mt: -8,
          p: 4,
          mb: 10,
          justifyContent: "center",
          width: "100%",
          bg: "avocado",
          alignItems: "center",
        }}
      >
        <Text sx={{ mr: 8, fontWeight: "bold" }}>Ready to supercharge your marketing team?</Text>
        <Button
          onClick={() => {
            newIntercomMessage("I would like a demo of Hightouch Audiences!");
          }}
        >
          Request a demo
        </Button>
      </Row>

      <Container center size="large" sx={{ px: 6 }}>
        <Column sx={{ alignItems: "center" }}>
          <AudienceIcon color="forest" size={64} />
          <Text sx={{ fontSize: 5, fontWeight: "bold", mt: 4, mb: 2 }}>Hightouch Audiences</Text>
          <Text sx={{ color: "base.7", textAlign: "center" }}>
            The only audience builder that lets marketing teams personalize campaigns using ALL their data.
          </Text>
        </Column>

        <Row sx={{ justifyContent: "space-between", mt: 8, mb: 12, flexDirection: ["column", "column", "column", "row"] }}>
          <Column sx={{ m: 8, maxWidth: "500px", color: "base.7", gap: 6 }}>
            <Text sx={{ fontWeight: "bold", fontSize: 3, color: "black" }}>The most flexible audience builder</Text>

            <Row>
              <CheckCircleIcon color="grass" sx={{ mr: 4 }} />
              <Text>
                Since Hightouch simply sits on your warehouse, you can leverage any information about your customers, including
                product actions, billing information and 3rd party data sources.
              </Text>
            </Row>
            <Row>
              <CheckCircleIcon color="grass" sx={{ mr: 4 }} />
              <Text>
                Define audiences using custom objects specific to your business, like workspaces, accounts, products, etc.
              </Text>
            </Row>
            <Row>
              <CheckCircleIcon color="grass" sx={{ mr: 4 }} />
              <Text>
                Get started instantly with the data you already have. It's not uncommon for CDPs to take up to a year to
                implement because you need to use their tracking library and rigid data model.
              </Text>
            </Row>
            <Row>
              <CheckCircleIcon color="grass" sx={{ mr: 4 }} />
              <Text>
                Send audiences at scale without hitting API rate limits, MTU/MAU charges or overages in your marketing tools.
              </Text>
            </Row>
          </Column>

          <Column
            sx={{
              video: { borderRadius: 2, width: "100%", height: "100%" },
              p: 4,
              m: 8,
              borderRadius: 2,
              bg: "base.1",
              alignItems: "center",
              justifyContent: "center",
              width: "500px",
              flexShrink: 0,
            }}
          >
            <video
              controls={true}
              poster="https://cdn.sanity.io/images/pwmfmi47/production/4d576498380ac0f1282f371143db1533ed9efdf3-1500x900.png"
              src="https://cdn.sanity.io/files/pwmfmi47/production/bbd24af51d616f02c9cc8402827785652e0c1529.mp4"
            />
          </Column>
        </Row>

        <Column sx={{ alignItems: "center" }}>
          <Text
            sx={{ fontWeight: "bold", color: "base.4", mb: 2, textTransform: "uppercase", letterSpacing: "1px", fontSize: 0 }}
          >
            Audiences is used by
          </Text>
          <Logos />
        </Column>
      </Container>
    </Page>
  );
};
