import { FC } from "react";

import { PageHeader } from "src/components/layout";
import { useOnboardingData } from "src/components/onboarding/onboarding-reducer";
import { useUser } from "src/contexts/user-context";
import { Column } from "src/ui/box";

import { Footer, ProgressNav, Diagram } from "../../components/onboarding/index";

export const Onboarding: FC = () => {
  const { onboarding, workspace } = useUser();

  const [onboardingData, setOnboardingData, onboardingLoading] = useOnboardingData(workspace!, onboarding);

  return (
    <Column sx={{ width: "100%", alignItems: "center", flex: 1 }}>
      <PageHeader
        crumbs={[{ label: "Setup", link: "/onboarding" }]}
        outsideTopbar={onboardingData.currentStep > 0 && <ProgressNav currentStep={onboardingData.currentStep} />}
      />
      <Column sx={{ width: "100%", flexGrow: "1", mt: 4, px: 6, alignItems: "center" }}>
        <Diagram
          currentStep={onboardingData.currentStep}
          loading={onboardingLoading}
          onboardingData={onboardingData}
          setOnboardingData={setOnboardingData}
        />
      </Column>
      <Footer />
    </Column>
  );
};
