import { FC } from "react";

import Switch from "react-switch";
import { Flex, Text, ThemeUIStyleObject } from "theme-ui";

import { usePermission } from "src/contexts/permission-context";
import { Tooltip } from "src/ui/tooltip";

export interface ToggleProps {
  value: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  sx?: ThemeUIStyleObject;
  textSx?: ThemeUIStyleObject;
  disabled?: boolean;
  tooltipText?: string;
}

export const Toggle: FC<Readonly<ToggleProps>> = ({
  disabled = false,
  label,
  sx = {},
  textSx = {},
  tooltipText,
  value,
  onChange,
}) => {
  const permission = usePermission();

  return (
    <Flex sx={{ alignItems: "center", cursor: disabled ? "not-allowed" : "pointer", ...sx }}>
      {label && (
        <Tooltip disabled={!tooltipText} text={tooltipText}>
          <Text
            sx={{
              textTransform: "uppercase",
              mr: 2,
              fontSize: "10px",
              color: "base.4",
              fontWeight: "bold",
              cursor: "auto",
              ...textSx,
            }}
          >
            {label}
          </Text>
        </Tooltip>
      )}
      <Switch
        checked={value}
        checkedIcon={false}
        disabled={disabled || permission?.unauthorized}
        height={18}
        offColor="#A7AFBC"
        uncheckedIcon={false}
        width={32}
        onChange={onChange}
        onColor="#03484D"
      />
    </Flex>
  );
};
