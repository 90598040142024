import { useCallback, useState } from "react";

import slugify from "@sindresorhus/slugify";
import { customAlphabet } from "nanoid";
import { lowercase, numbers } from "nanoid-dictionary";
import { useDebounce } from "use-debounce";

import { useGenerateResourceSlugQuery } from "src/graphql";

const nanoid = customAlphabet(lowercase + numbers, 5);

export const generateSlug = (prefix: string) => slugify(`${prefix}-${nanoid()}`);

export enum ResourceType {
  Source = "connections",
  Destination = "destinations",
  Sync = "syncs",
  SyncTemplate = "sync_templates",
  Model = "segments",
  Sequence = "sync_sequences",
}

export const useResourceName = (type: ResourceType) => {
  const [name, setName] = useState("");
  const [clientSlug, setClientSlug] = useState("");
  const [debouncedSlug, { isPending }] = useDebounce(clientSlug, 1000);

  const update = useCallback(
    (name: string) => {
      setName(name);
      setClientSlug(slugify(name || ""));
    },
    [setName, setClientSlug],
  );

  const { data: slug, isFetching } = useGenerateResourceSlugQuery(
    { resourceType: type, slug: debouncedSlug },
    { enabled: Boolean(debouncedSlug), select: (data) => data.generateResourceSlug },
  );

  return { name, slug, setName: update, loading: isPending() || isFetching };
};
