import { FC, useState } from "react";

import * as Sentry from "@sentry/react";
import { useLocation } from "react-router-dom";
import { Text } from "theme-ui";

import { Fade } from "src/ui/animations";
import { Button } from "src/ui/button";
import { Card } from "src/ui/card";
import { Input } from "src/ui/input";
import { Link } from "src/ui/link";
import { useQueryString } from "src/utils/use-query-string";

import { Header, Terms } from "./continue";

interface LocationState {
  from?: {
    pathname: string;
  };
}

export const Passwordless: FC = () => {
  const location = useLocation();
  const locationState = location.state as LocationState;
  const {
    data: { returnTo: queryLocation },
  } = useQueryString();
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const returnTo = queryLocation || locationState?.["from"]?.["pathname"];

  const submit = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/auth/email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          returnTo,
        }),
      });
      if (response.ok) {
        setSuccess(true);
      } else {
        setError(true);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <Card sx={{ maxWidth: "550px", width: "100%" }}>
      <Fade sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
        <Header />

        {success && (
          <>
            <Text sx={{ fontSize: 4, fontWeight: "bold", mb: 4 }}>Email sent</Text>
            <Text sx={{ textAlign: "center", color: "base.7" }}>
              An email has been sent to <strong>{email}</strong> with a link to continue.
            </Text>
          </>
        )}

        {error && (
          <>
            <Text sx={{ fontSize: 4, fontWeight: "bold", mb: 4 }}>Enterprise email</Text>
            <Text sx={{ textAlign: "center", color: "base.7" }}>
              This email is tied to an enterprise organization, go to{" "}
              <Link slug={false} to="/sso">
                enterprise sign in
              </Link>{" "}
              to continue.
            </Text>
          </>
        )}

        {!success && !error && (
          <form
            onSubmit={(event) => {
              event.preventDefault();
              submit();
            }}
          >
            <Input
              autoFocus
              placeholder="mark.scout@lumon.com"
              size="large"
              sx={{ mb: 4, width: "250px" }}
              value={email}
              onChange={setEmail}
            />

            <Button label="Continue with Email" size="large" sx={{ width: "250px" }} type="submit" variant="primary" />
          </form>
        )}

        <Link
          slug={false}
          sx={{
            mt: 10,
            fontSize: 2,
            fontWeight: "bold",
            color: "base.4",
            ":hover": { color: "base.7" },
            transition: "150ms color",
          }}
          to={returnTo ? `/login?returnTo=${returnTo}` : "/login"}
        >
          {`<- Other options`}
        </Link>
        <Terms />
      </Fade>
    </Card>
  );
};
