import { FC, useMemo, useState } from "react";

import { Image } from "theme-ui";

import { LabelsCell } from "src/components/labels/labels-cell";
import placeholderSource from "src/components/permission/source.svg";
import { ResourceSelect } from "src/components/resource-select";
import { useParentsQuery, ParentModelsQuery } from "src/graphql";
import { Row } from "src/ui/box";
import { TableColumn } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { TextWithTooltip } from "src/ui/text";
import { Tooltip } from "src/ui/tooltip";

export type ParentModel = ParentModelsQuery["segments"][0];

interface Props {
  onSelect: (ParentModel) => void;
}

export const ParentModelSelect: FC<Readonly<Props>> = ({ onSelect }) => {
  const [search, setSearch] = useState("");

  const { data: parentModels, isFetching: loading, error } = useParentsQuery(undefined, { select: (data) => data.segments });

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        cell: ({ name, connection, tags }) => {
          return (
            <Tooltip disabled={Boolean(connection)} text="This destination is only visible to some users">
              <Row sx={{ alignItems: "center", mt: 1 }}>
                <Image
                  alt={connection.definition?.name ?? "Private Source"}
                  src={connection.definition?.icon ?? placeholderSource}
                  sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", mr: 2, flexShrink: 0 }}
                />
                <TextWithTooltip disabled={!name} sx={{ fontWeight: "bold", maxWidth: "32vw", color: "black" }} text={name}>
                  {name ?? "Private Source"}
                </TextWithTooltip>

                <LabelsCell labels={tags} />
              </Row>
            </Tooltip>
          );
        },
      },
      {
        ...LastUpdatedColumn,
        max: "300px",
      },
    ],
    [],
  );

  return (
    <ResourceSelect
      columns={columns}
      data={parentModels}
      error={Boolean(error)}
      label="parent model"
      loading={loading}
      search={search}
      onSearch={setSearch}
      onSelect={onSelect}
    />
  );
};
