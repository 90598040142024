import { useEffect, useMemo, useState } from "react";

import { Button, ButtonGroup, Text, SearchInput, Box } from "@hightouchio/ui";
import pluralize from "pluralize";
import { useParams } from "react-router-dom";

import { SyncsCell } from "src/components/syncs/syncs-cell";
import {
  AudiencesForPriorityListsQuery,
  SegmentsBoolExp,
  SegmentsOrderBy,
  useAudiencesForPriorityListsQuery,
} from "src/graphql";
import { Row } from "src/ui/box";
import { Modal } from "src/ui/modal";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useRowSelect } from "src/ui/table/use-row-select";
import { TextWithTooltip } from "src/ui/text";
import { useDestinations } from "src/utils/destinations";
import { abbreviateNumber } from "src/utils/numbers";

enum SortKeys {
  Name = "name",
  NumSyncs = "syncs_aggregate.count",
  UpdatedAt = "updated_at",
}

export const AudienceSelector = ({ parentModelId, isOpen, onClose, selectedData, onSubmit }) => {
  const { priority_list_id } = useParams();
  const [search, setSearch] = useState("");
  const { selectedRows, onRowSelect } = useRowSelect();
  const [selectedAudiences, setSelectedAudiences] = useState<AudiencesForPriorityListsQuery["segments"][0][]>([]);
  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<SegmentsOrderBy>({
    defaultSortKey: "updated_at",
    limit: 10,
    sortOptions: Object.values(SortKeys),
  });

  const filters: SegmentsBoolExp = useMemo(() => {
    const orFilters: SegmentsBoolExp["_or"] = [
      // Only fetch audiences who don't belong to any other priority lists
      // https://hasura.io/docs/latest/queries/postgres/query-filters/#fetch-if-nested-objects-existdo-not-exist
      { _not: { priority_list_memberships: {} } },
    ];

    if (priority_list_id) {
      orFilters.push({ priority_list_memberships: { priority_list_id: { _eq: priority_list_id } } });
    }

    const hasuraFilters: SegmentsBoolExp = {
      query_type: { _eq: "visual" },
      visual_query_parent_id: { _eq: parentModelId },
      _or: orFilters,
    };

    if (search) {
      hasuraFilters.name = { _ilike: `%${search}%` };
    }

    return hasuraFilters;
  }, [priority_list_id, parentModelId, search]);

  const destinationsQueries = useDestinations();

  const audiencesForPriorityListsQuery = useAudiencesForPriorityListsQuery(
    {
      filters,
      limit,
      offset,
      orderBy,
    },
    {
      enabled: Boolean(parentModelId),
    },
  );

  const audiences = audiencesForPriorityListsQuery.data?.segments ?? [];
  const audiencesCount = audiencesForPriorityListsQuery.data?.segments_aggregate?.aggregate?.count ?? 0;

  const submit = () => {
    onSubmit(selectedAudiences);
  };

  useEffect(() => {
    onRowSelect(selectedData.map(({ id }) => id));
  }, [selectedData]);

  useEffect(() => {
    // save all audience data (including audiences not on current page)
    if (audiences.length > 0) {
      // Start with old audiences
      const newAudiences = audiences.filter(({ id }) => selectedRows.includes(id));

      // add in new audiences that were selected
      selectedRows.forEach((id) => {
        // If old audiences doesn't have the audience data, add in the audience data
        if (!newAudiences.find((audience) => audience?.id === id)) {
          const audience = audiences.find((audience) => audience.id === id);

          if (audience) {
            newAudiences.push(audience);
          }
        }
      });

      setSelectedAudiences(newAudiences);
    }
  }, [audiences, selectedRows]);

  const columns: TableColumn[] = useMemo(
    () => [
      {
        name: "Name",
        sortDirection: orderBy?.name,
        onClick: () => onSort(SortKeys.Name),
        cell: ({ name }) => (
          <Row sx={{ alignItems: "center" }}>
            <TextWithTooltip sx={{ maxWidth: "350px" }} text={name}>
              {name}
            </TextWithTooltip>
          </Row>
        ),
      },
      {
        name: "Size",
        key: "query_runs.[0].size",
        max: "max-content",
        cell: (size) =>
          size ? (
            <Text>{abbreviateNumber(size)}</Text>
          ) : (
            <Box color="gray.00">
              <Text>--</Text>
            </Box>
          ),
      },
      {
        name: "Syncs",
        sortDirection: orderBy?.syncs_aggregate?.count,
        onClick: () => onSort(SortKeys.NumSyncs),
        max: "max-content",
        disabled: ({ syncs }) => Boolean(syncs?.length),
        cell: ({ syncs }) => {
          return <SyncsCell definitions={destinationsQueries.data.definitions ?? []} syncs={syncs} />;
        },
      },
      {
        ...LastUpdatedColumn,
        sortDirection: orderBy?.updated_at,
        onClick: () => onSort(SortKeys.UpdatedAt),
      },
    ],
    [destinationsQueries.data.definitions, orderBy, onSort],
  );

  return (
    <Modal
      bodySx={{ bg: "white" }}
      footer={
        <ButtonGroup>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isDisabled={audiencesCount === 0}
            variant="primary"
            onClick={() => {
              submit();
              onClose();
            }}
          >
            Add audiences
          </Button>
        </ButtonGroup>
      }
      isOpen={isOpen}
      sx={{ width: "100%" }}
      title="Add audiences to priority list"
      onClose={onClose}
    >
      <Row sx={{ mb: 4, justifyContent: "space-between", alignItems: "center" }}>
        <SearchInput
          placeholder="Search for audiences that belong to this parent model"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        {selectedRows?.length > 0 && (
          <Row sx={{ color: "base.7" }}>
            <Text>
              {selectedRows.length} {pluralize("audience", selectedRows.length)} selected
            </Text>
          </Row>
        )}
      </Row>
      <Table
        columns={columns}
        data={audiences}
        error={Boolean(audiencesForPriorityListsQuery.error)}
        loading={audiencesForPriorityListsQuery.isLoading}
        placeholder={{
          title: "No audiences",
          body: "This parent model may not have any audiences, or all audiences are being used in other priority lists.",
          error: "Priority lists failed to load, please try again.",
        }}
        selectedRows={selectedRows}
        onSelect={onRowSelect}
      />
      <Pagination count={audiencesCount} label="audiences" page={page} rowsPerPage={limit} setPage={setPage} />
    </Modal>
  );
};
