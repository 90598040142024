import { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { isEqual } from "lodash";
import { format } from "numerable";
import { useQueryClient } from "react-query";
import { Image, Text } from "theme-ui";
import { UnreachableCaseError } from "ts-essentials";

import AudienceIllustration from "src/components/audiences/audience-illustration.svg";
import { Editor } from "src/components/editor";
import { QueryBuilder } from "src/components/explore/query-builder";
import {
  AudienceBreakdownResult,
  AudienceComparisonResult,
  CompareAudiencesQueryVariables,
  ResourcePermissionGrant,
  useAudiencesForDropdownsQuery,
  useCompareAudiencesQuery,
  useAudienceBreakdownQuery,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";
import {
  Audience,
  AudienceParent,
  Condition,
  ConditionType,
  OrCondition,
  RootCondition,
  VisualQueryFilter,
} from "src/types/visual";
import { Box, Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { DotsIcon, DoubleArrowLeftIcon, ResizeContractIcon, ResizeExpandIcon } from "src/ui/icons";
import { Spinner } from "src/ui/loading";
import { Menu } from "src/ui/menu";
import { Modal } from "src/ui/modal";
import { Overlay, OverlayProps } from "src/ui/overlay";
import ErrorSrc from "src/ui/table/error.svg";
import { Tabs } from "src/ui/tabs";
import { TextWithTooltip } from "src/ui/text";
import { UseSourceResult } from "src/utils/sources";

import { Permission } from "../permission";
import { AudienceBreakdowns, BreakdownColumn, ChartType } from "./audience-breakdowns";
import { AudienceOverlap } from "./audience-overlap";
import { AudienceResults } from "./audience-results";

type Source = NonNullable<UseSourceResult["data"]>;

enum Tab {
  Results = "Results",
  Overlap = "Overlap",
  Breakdowns = "Breakdown",
}

const minSidebarX = 150;
const minSidebarWidth = 300;
const defaultSidebarWidth = 400;

export type AudienceExploreProps = {
  audience?: Audience;
  parentModel?: AudienceParent | null;
  transformedSql: string | undefined | null;
  runQuery: (limit: boolean) => Promise<any>;
  cancelQuery: () => void;
  visualQueryFilter?: VisualQueryFilter;
  onVisualQueryFilterChange?: (filter: VisualQueryFilter) => void;
  source: Source | undefined | null;
  rowsPerPage?: number;
  error: string | undefined;
  rows?: any;
  numRowsWithoutLimit?: number | null;
  columns?: any;
  loading?: boolean;
  reset?: () => void;
  onSave?: ((data?: { rows: any; columns: any }) => Promise<void>) | undefined;
  getSchema: () => Promise<any>;
  saveLabel?: string;
};

export const AudienceExplore: FC<Readonly<AudienceExploreProps>> = ({
  audience,
  parentModel,
  numRowsWithoutLimit,
  rows,
  columns,
  loading,
  saveLabel,
  error,
  source,
  transformedSql,
  visualQueryFilter,
  getSchema,
  runQuery,
  cancelQuery,
  reset,
  onVisualQueryFilterChange,
  onSave,
}) => {
  const client = useQueryClient();
  const { appAudienceOverlap, appFullScreenQueryBuilder, appAudienceBreakdowns } = useFlags();
  const [hasQueryColumns, setHasQueryColumns] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [sidebarPosition, setSidebarPosition] = useState(() => window.innerWidth - defaultSidebarWidth);
  const [sidebarWidth, setSidebarWidth] = useState(defaultSidebarWidth);
  const [dragging, setDragging] = useState(false);
  const [tab, setTab] = useState<Tab>(Tab.Results);
  const [chartType, setChartType] = useState<ChartType>("bar");
  const [overlapQueryError, setOverlapQueryError] = useState(false);
  const [lastRunQueryPerTab, setLastRunQueryPerTab] = useState<Record<Tab, VisualQueryFilter | undefined>>({
    [Tab.Results]: undefined,
    [Tab.Overlap]: undefined,
    [Tab.Breakdowns]: undefined,
  });

  const { hasPermission: userCanPreviewAudience } = useHasPermission([
    { resource: "audience", grants: [ResourcePermissionGrant.Preview], resource_id: audience?.id },
  ]);

  const [comparedAudienceIds, setComparedAudienceIds] = useState<string[]>([]);
  const [comparisonData, setComparisonData] = useState<AudienceComparisonResult>();
  const [comparisonsLoading, setComparisonsLoading] = useState(false);

  const refetchBreakdown = useRef(false);
  const [breakdownColumns, setBreakdownColumns] = useState<BreakdownColumn[]>([]);
  const [breakdownData, setBreakdownData] = useState<AudienceBreakdownResult>();

  const [audienceSize, setAudienceSize] = useState<number | null>(numRowsWithoutLimit ?? comparisonData?.audienceSize ?? null);

  const sidebarRef = useRef<HTMLDivElement | null>(null);

  const {
    error: breakdownError,
    isLoading: breakdownsInitialLoading,
    isRefetching: breakdownsRefetching,
    refetch: refetchBreakdowns,
  } = useAudienceBreakdownQuery(
    {
      id: audience?.connection?.id?.toString() ?? source?.id?.toString() ?? "",
      parentModelId: audience?.parent?.id?.toString() ?? parentModel?.id?.toString() ?? "",
      columns: breakdownColumns,
      filter: visualQueryFilter ?? { conditions: [] },
      audienceId: audience ? audience.id.toString() : undefined,
      perColumnLimit: 10,
    },
    {
      enabled: false,
      keepPreviousData: breakdownColumns?.length > 0 ? false : true,
      onSuccess: (result) => {
        setAudienceSize(result.audienceBreakdown.audienceSize);
        const formattedColumns: AudienceBreakdownResult["columns"] = [];

        for (const column of result?.audienceBreakdown.columns ?? []) {
          const sumOfValues = column.values.reduce((sum, value) => sum + value.count, 0);
          let newValues = column.values.map((value) => ({
            ...value,
            percentage: sumOfValues ? value.count / sumOfValues : "unknown",
          }));
          const numberOfRemainingValues = result.audienceBreakdown.audienceSize - sumOfValues;

          if (numberOfRemainingValues > 0) {
            newValues = newValues.map((value) => ({
              ...value,
              percentage: value.count / result.audienceBreakdown.audienceSize,
            }));
            newValues.push({
              value: "Other",
              count: numberOfRemainingValues,
              percentage: numberOfRemainingValues / result.audienceBreakdown.audienceSize,
            });
            newValues.sort((valueA, valueB) => valueB.count - valueA.count);
          }

          formattedColumns.push({ ...column, values: newValues });
        }

        setBreakdownData({ ...result?.audienceBreakdown, columns: formattedColumns });
        setLastRunQueryPerTab((previousData) => ({
          ...previousData,
          [Tab.Breakdowns]: visualQueryFilter,
        }));
      },
      onError: () => {
        setLastRunQueryPerTab((previousData) => ({
          ...previousData,
          [Tab.Breakdowns]: visualQueryFilter,
        }));
      },
    },
  );

  const { data: audiencesData, isLoading: audiencesLoading } = useAudiencesForDropdownsQuery(
    { limit: 5000 },
    {
      enabled: Boolean(appAudienceOverlap),
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60, // 1 min
      notifyOnChangeProps: ["data", "isLoading"],
    },
  );

  const breakdownsLoading = breakdownsInitialLoading || breakdownsRefetching;
  const audiences = audiencesData?.segments ?? [];
  const columnPossibilities = (audience?.syncable_columns ?? parentModel?.filterable_audience_columns ?? [])
    .map(({ column_reference }) => column_reference)
    .filter(
      (columnReference) =>
        columnReference?.type === "raw" && !breakdownColumns.some((selectedColumn) => isEqual(selectedColumn, columnReference)),
    );

  const supportsResultSchema = Boolean(source?.definition?.supportsResultSchema);

  const splitTestGroupName = audience?.syncable_columns.find(({ column_reference }) => {
    return column_reference.type === "splitTest";
  })?.name;

  const staleDataInTab = useMemo(() => {
    const hideResultsRefresh = (!rows && !error) || loading;
    const hideComparisonsRefresh = !comparisonData || comparisonsLoading;
    const hideBreakdownRefresh = breakdownColumns?.length === 0 || !breakdownData || breakdownsLoading;

    if (
      (tab === Tab.Results && hideResultsRefresh) ||
      (tab === Tab.Overlap && hideComparisonsRefresh) ||
      (tab === Tab.Breakdowns && hideBreakdownRefresh)
    ) {
      return false;
    }

    return !isEqual(lastRunQueryPerTab[tab], visualQueryFilter);
  }, [
    comparisonData,
    comparisonsLoading,
    error,
    loading,
    rows,
    tab,
    visualQueryFilter,
    breakdownData,
    breakdownColumns?.length,
    breakdownsLoading,
  ]);

  const toggleFullscreen = () => {
    if (fullscreen) {
      setShowSidebar(true);
      setSidebarPosition(window.innerWidth - defaultSidebarWidth);
    }

    setFullscreen((previousValue) => !previousValue);
  };

  const handleSave = async () => {
    setSaveLoading(true);

    if (supportsResultSchema && !hasQueryColumns) {
      const { data } = await getSchema();

      if (data?.columns?.length && typeof onSave === "function") {
        await onSave(data);
      }
    } else if (typeof onSave === "function") {
      await onSave();
    }

    setSaveLoading(false);
  };

  const resetOverlapData = () => {
    setComparedAudienceIds([]);
    setComparisonData(undefined);
  };

  const addBreakdown = (column: BreakdownColumn) => {
    const newBreakdownColumns = [...breakdownColumns, column];
    setBreakdownColumns(newBreakdownColumns);
  };

  const removeBreakdown = (columnName: string) => {
    refetchBreakdown.current = false;

    // remove data
    setBreakdownData((prevData) => {
      if (!prevData) {
        return undefined;
      }

      const newColumns = [...(prevData?.columns ?? [])].filter((column) => column.name !== columnName);
      if (newColumns.length === 0) {
        return undefined;
      }

      return {
        ...prevData,
        columns: newColumns,
      };
    });

    // remove column
    setBreakdownColumns((prevColumns) => prevColumns.filter((column) => column.name !== columnName));
  };

  const clearBreakdowns = () => {
    refetchBreakdown.current = false;
    setBreakdownColumns([]);
    setBreakdownData(undefined);
  };

  useEffect(() => {
    if (refetchBreakdown.current && breakdownColumns.length > 0) {
      refetchBreakdowns();
    }

    refetchBreakdown.current = true;
  }, [breakdownColumns.length]);

  const compareAudiences = async (
    audienceIds: string[],
    { invalidateQuery }: { invalidateQuery: boolean } = { invalidateQuery: false },
  ) => {
    if (audienceIds.length === 0) {
      setComparedAudienceIds([]);
      setComparisonData(undefined);
      setOverlapQueryError(false);
      return;
    }

    setComparisonsLoading(true);

    const variables: CompareAudiencesQueryVariables = {
      id: audience?.connection?.id?.toString() ?? source?.id?.toString() ?? "",
      parentModelId: audience?.parent?.id?.toString() ?? parentModel?.id?.toString() ?? "",
      compareToAudiences: audienceIds || comparedAudienceIds,
      filter: visualQueryFilter ?? { conditions: [] },
      audienceId: audience ? audience.id.toString() : undefined,
    };

    const queryKey = useCompareAudiencesQuery.getKey(variables);

    if (invalidateQuery) {
      await client.invalidateQueries(queryKey);
    }

    try {
      const { audienceComparison } = await client.fetchQuery({
        queryFn: useCompareAudiencesQuery.fetcher(variables),
        queryKey,
      });

      setComparedAudienceIds(audienceIds || comparedAudienceIds);
      setAudienceSize(audienceComparison?.audienceSize);
      setComparisonData(audienceComparison);
      setOverlapQueryError(false);
    } catch (error) {
      // TODO: Log only 500 errors to sentry
      setOverlapQueryError(true);
    }

    setLastRunQueryPerTab((previousData) => ({
      ...previousData,
      [Tab.Overlap]: visualQueryFilter,
    }));

    setComparisonsLoading(false);
  };

  const removeComparison = (idToRemove: string) => {
    setComparedAudienceIds((previousComparedIds) => {
      const newIds = [...previousComparedIds];
      const indexToRemove = newIds.findIndex((comparedId) => comparedId === idToRemove);

      if (indexToRemove > -1) {
        newIds.splice(indexToRemove, 1);
      }

      return newIds;
    });

    setComparisonData((previousComparedData) => {
      if (!previousComparedData) {
        return previousComparedData;
      }

      const newComparedData = {
        ...previousComparedData,
        comparisons: [...previousComparedData.comparisons],
      };

      const indexToRemove = newComparedData.comparisons.findIndex(
        (comparedAudience) => comparedAudience.audienceId === idToRemove,
      );

      if (indexToRemove > -1) {
        newComparedData.comparisons.splice(indexToRemove, 1);
      }

      return newComparedData;
    });
  };

  const handleRunQuery = useCallback(
    ({ limitResults }: { limitResults: boolean }) => {
      setLastRunQueryPerTab((previousData) => ({
        ...previousData,
        [Tab.Results]: visualQueryFilter,
      }));

      return runQuery(limitResults);
    },
    [runQuery, visualQueryFilter],
  );

  const previewQuery = async () => {
    await handleRunQuery({ limitResults: true });
    analytics.track("Model Query Previewed", {
      model_type: source?.definition?.type,
      query_mode: "visual",
    });
  };

  useEffect(() => {
    setAudienceSize(numRowsWithoutLimit ?? null);
  }, [numRowsWithoutLimit]);

  const refreshResults = (newTab: Tab) => {
    switch (newTab) {
      case Tab.Results:
        previewQuery();
        break;
      case Tab.Overlap:
        if (comparedAudienceIds.length) {
          compareAudiences(comparedAudienceIds, { invalidateQuery: true });
        }
        break;
      case Tab.Breakdowns:
        if (breakdownColumns.length > 0) {
          refetchBreakdowns();
        }
        return;
      default:
        throw new UnreachableCaseError(newTab);
    }

    setLastRunQueryPerTab((previousStaleTabs) => ({
      ...previousStaleTabs,
      [newTab]: visualQueryFilter,
    }));
  };

  const clickTab = (tab) => {
    setTab(tab as Tab);

    const ignoreResultsRefresh = (!rows && !error) || loading;
    const ignoreComparisonsRefresh = !comparisonData || comparisonsLoading;
    const ignoreBreakdownRefresh = breakdownColumns.length === 0 || breakdownsLoading;

    if (
      ((tab === Tab.Results && !ignoreResultsRefresh) ||
        (tab === Tab.Overlap && !ignoreComparisonsRefresh) ||
        (tab === Tab.Breakdowns && !ignoreBreakdownRefresh)) &&
      !isEqual(lastRunQueryPerTab[tab], visualQueryFilter)
    ) {
      refreshResults(tab);
    }
  };

  useEffect(() => {
    setHasQueryColumns(false);
  }, [visualQueryFilter]);

  useEffect(() => {
    if (columns?.length && !error) {
      setHasQueryColumns(true);
    }
  }, [rows, columns]);

  const [showTransformedSql, setShowTransformedSql] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      analytics.track("Model Query Error", {
        model_type: source?.definition?.type,
        query_mode: "visual",
        error,
      });
    }
  }, [error]);

  useEffect(() => {
    const handler = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        handleRunQuery({ limitResults: true });
      }
    };
    window.addEventListener("keydown", handler);

    return () => window.removeEventListener("keydown", handler);
  }, [handleRunQuery]);

  useEffect(() => {
    return reset;
  }, []);

  // Set body height to prevent page from scrolling
  useEffect(() => {
    const originalValue = document.body.style.height;

    document.body.style.height = "100vh";

    return () => {
      document.body.style.height = originalValue;
    };
  });

  useEffect(() => {
    const node = sidebarRef.current;

    if (node) {
      let result = fullscreen ? window.innerWidth - sidebarPosition : node.clientWidth;

      if (result < minSidebarWidth) {
        result = minSidebarWidth;
      } else if (fullscreen && sidebarPosition < minSidebarX) {
        result = window.innerWidth - minSidebarX;
      }

      setSidebarWidth(result);
    }
  }, [fullscreen, sidebarPosition]);

  useEffect(() => {
    const node = sidebarRef.current;

    const resizeSidebar = () => {
      if (node) {
        setSidebarPosition(node.clientWidth);
      }
    };

    window.addEventListener("resize", resizeSidebar);

    return () => {
      window.removeEventListener("resize", resizeSidebar);
    };
  }, []);

  useEffect(() => {
    if (!dragging) {
      return;
    }

    const resizeWindow = (event: MouseEvent) => {
      setSidebarPosition(event.clientX);
    };
    const finishDrag = () => setDragging(false);

    const originalCursor = document.body.style.cursor;
    const originalUserSelect = document.body.style.userSelect;
    const originalPointerEvents = document.body.style.pointerEvents;

    document.body.style.cursor = "col-resize";
    document.body.style.userSelect = "none";
    document.body.style.pointerEvents = "none";

    document.addEventListener("mousemove", resizeWindow);
    document.addEventListener("mouseup", finishDrag);

    return () => {
      document.removeEventListener("mousemove", resizeWindow);
      document.removeEventListener("mouseup", finishDrag);

      document.body.style.cursor = originalCursor;
      document.body.style.userSelect = originalUserSelect;
      document.body.style.pointerEvents = originalPointerEvents;
    };
  }, [dragging]);

  useEffect(() => {
    const closeFullscreen = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setFullscreen(false);
      }
    };

    document.body.addEventListener("keydown", closeFullscreen);

    return () => {
      document.body.removeEventListener("keydown", closeFullscreen);
    };
  }, []);

  const TABS = [
    Tab.Results,
    appAudienceOverlap && userCanPreviewAudience && Tab.Overlap,
    appAudienceBreakdowns && userCanPreviewAudience && Tab.Breakdowns,
  ].filter(Boolean);

  let refreshResultsDisabled = false;
  if (tab === Tab.Results) {
    refreshResultsDisabled = loading || (!rows && !error);
  } else if (tab === Tab.Overlap) {
    refreshResultsDisabled = !comparisonData || comparisonsLoading;
  } else if (tab === Tab.Breakdowns) {
    refreshResultsDisabled = breakdownColumns.length === 0 || breakdownsLoading;
  }

  const displayBreakdownError =
    breakdownError && !breakdownError?.message.includes("missing columns for breakdown") && !breakdownsLoading;

  return (
    <OverlayWrapper fullscreen isOpen={fullscreen} sx={{ bg: "white", height: "100%" }}>
      <Column
        sx={{
          border: "small",
          borderRadius: 3,
          flex: 1,
          minHeight: 0,
          height: "100%",
        }}
      >
        <Row sx={{ position: "relative", height: "100%", flex: 1, minHeight: 0 }}>
          <Column sx={{ flex: 2, p: 5, overflowY: "auto" }}>
            <QueryBuilder
              audience={audience}
              conditions={toOrConditions(visualQueryFilter?.conditions)}
              parent={parentModel}
              setConditions={(conditions) => {
                if (typeof onVisualQueryFilterChange === "function") {
                  onVisualQueryFilterChange({ ...(visualQueryFilter || {}), conditions: conditions as RootCondition[] });
                }
              }}
            />
          </Column>

          {fullscreen && (
            <Box
              sx={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                right: 0,
                top: 5,
                width: "30px",
                border: "small",
                borderRight: 0,
                bg: "indigos.1",
                borderTopLeftRadius: 1,
                borderBottomLeftRadius: 1,
              }}
            >
              <Button aria-label="Show side panel" sx={{ width: "100%" }} variant="plain" onClick={() => setShowSidebar(true)}>
                <DoubleArrowLeftIcon color="white" size={13} />
              </Button>
            </Box>
          )}

          <Column
            ref={sidebarRef}
            sx={{
              bg: "white",
              flex: 1,
              minHeight: 0,
              borderTopRightRadius: 3,
              width: `${sidebarWidth}px`,
              ...(fullscreen
                ? {
                    position: "absolute",
                    right: showSidebar ? 0 : -window.innerWidth,
                    height: "100%",
                    transition: "right ease-in-out 250ms",
                  }
                : { position: "relative" }),
            }}
          >
            <Permission
              permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Preview], resource_id: audience?.id }]}
            >
              {staleDataInTab && (
                <Row
                  sx={{ alignItems: "center", justifyContent: "space-between", p: 5, bg: "#eff8ff", borderTopRightRadius: 3 }}
                >
                  <Text sx={{ mr: 2, color: "base.9", fontWeight: 500, fontSize: 0 }}>Your conditions have changed</Text>
                  <Button variant="secondary" onClick={() => refreshResults(tab)}>
                    Refresh results
                  </Button>
                </Row>
              )}
            </Permission>

            {fullscreen && (
              <>
                <Box
                  sx={{
                    position: "absolute",
                    ml: -1,
                    height: "100%",
                    cursor: "col-resize",
                    width: "10px",
                    zIndex: 1,
                  }}
                  onMouseDown={() => setDragging(true)}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    ml: "3px",
                    height: "40px",
                    transform: "translateY(-50%)",
                    border: "small",
                    bg: "base.2",
                    width: "3px",
                    borderRadius: "4px",
                    pointerEvents: "none",
                  }}
                />
              </>
            )}

            <Column
              sx={{
                pt: 5,
                borderLeft: "small",
                width: "100%",
                height: "100%",
                borderTopRightRadius: 3,
                overflow: "hidden",
              }}
            >
              <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 3, ml: 1, mr: 2 }}>
                <Row sx={{ alignItems: "center" }}>
                  <TextWithTooltip
                    sx={{
                      flex: 1,
                      mx: 2,
                      color: "base.6",
                      fontSize: 0,
                      maxWidth: "85px",
                    }}
                    text="Audience Size"
                  >
                    Audience size:
                  </TextWithTooltip>
                  <Text
                    sx={{
                      color: audienceSize === undefined ? "base.6" : "black",
                      mr: 2,
                      fontSize: 0,
                      fontWeight: "bold",
                    }}
                  >
                    {loading || comparisonsLoading || breakdownsLoading
                      ? "loading..."
                      : audienceSize === null
                      ? "---"
                      : format(audienceSize, "0,0")}
                  </Text>
                </Row>

                <Row sx={{ alignItems: "center", flexShrink: 0 }}>
                  {fullscreen && (
                    <Button aria-label="Hide side panel" sx={{ mr: 2 }} variant="plain" onClick={() => setShowSidebar(false)}>
                      <DoubleArrowLeftIcon color="base.4" size={13} sx={{ transform: "rotate(180deg)" }} />
                    </Button>
                  )}

                  <Menu
                    options={[
                      {
                        disabled: refreshResultsDisabled,
                        label: "Refresh results",
                        onClick: () => refreshResults(tab),
                      },
                      {
                        disabled: (!rows && !error) || loading,
                        label: "View SQL",
                        onClick: () => setShowTransformedSql(true),
                      },
                    ]}
                    placement="bottom-start"
                  >
                    <DotsIcon sx={{ transform: "rotate(90deg)", color: "base.5" }} />
                  </Menu>
                </Row>
              </Row>
              <Tabs setTab={clickTab} sx={{ div: { flexShrink: 0 } }} tab={tab} tabs={TABS} />

              {tab === Tab.Results &&
                ((rows || error) && !loading ? (
                  <AudienceResults
                    error={error}
                    parentModel={parentModel}
                    rows={rows}
                    splitTestGroupName={splitTestGroupName}
                  />
                ) : (
                  <Column
                    sx={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      p: 4,
                      borderTopRightRadius: 3,
                      overflowY: "auto",
                    }}
                  >
                    {loading ? (
                      <>
                        <Spinner size={64} />
                        <Text sx={{ mt: 6, color: "base.6" }}>Querying your data...</Text>
                        <Button size="small" sx={{ mt: 6 }} variant="secondary" onClick={cancelQuery}>
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Image src={AudienceIllustration} />
                        <Permission
                          permissions={[
                            { resource: "audience", grants: [ResourcePermissionGrant.Preview], resource_id: audience?.id },
                          ]}
                        >
                          <Button
                            loading={loading}
                            sx={{ my: 8, bg: "white" }}
                            variant="secondary"
                            onClick={async () => {
                              await handleRunQuery({ limitResults: true });
                              setLastRunQueryPerTab((previousData) => ({
                                ...previousData,
                                [Tab.Results]: visualQueryFilter,
                              }));
                              analytics.track("Model Query Previewed", {
                                model_type: source?.definition?.type,
                                query_mode: "visual",
                              });
                            }}
                          >
                            Preview results
                          </Button>
                        </Permission>
                        <Text sx={{ color: "base.5", textAlign: "center" }}>
                          Preview this audience to see a sample of results
                        </Text>
                      </>
                    )}
                  </Column>
                ))}

              {tab === Tab.Overlap &&
                (!overlapQueryError || comparisonsLoading ? (
                  <AudienceOverlap
                    audienceId={audience?.id}
                    audienceName={audience?.name ?? "Current audience"}
                    audiences={audiences ?? []}
                    comparedAudienceIds={comparedAudienceIds}
                    comparisonData={comparisonData}
                    loading={comparisonsLoading || audiencesLoading}
                    parentModelId={parentModel?.id}
                    onAddComparison={compareAudiences}
                    onClearComparisons={resetOverlapData}
                    onRemoveComparison={removeComparison}
                  />
                ) : (
                  <Column sx={{ alignItems: "center", pt: 24, px: 4, flex: 1, overflowY: "auto" }}>
                    <Image src={ErrorSrc} sx={{ mb: 4, maxWidth: "200px" }} />
                    <Text sx={{ fontWeight: "bold", fontSize: 2, textAlign: "center", mb: 2 }}>Error</Text>
                    <Text sx={{ color: "base.4", fontWeight: "bold", fontSize: 0, textAlign: "center", mb: 2 }}>
                      Update your query and try again
                    </Text>

                    <Button variant="secondary" onClick={() => compareAudiences([], { invalidateQuery: true })}>
                      Reset
                    </Button>
                  </Column>
                ))}

              {tab === Tab.Breakdowns &&
                (!displayBreakdownError ? (
                  <AudienceBreakdowns
                    breakdownData={breakdownData?.columns ?? []}
                    chartType={chartType}
                    columns={columnPossibilities}
                    graphWidth={fullscreen ? defaultSidebarWidth : sidebarWidth}
                    loading={breakdownsLoading || breakdownsRefetching}
                    onChartTypeChange={setChartType}
                    onClearBreakdowns={clearBreakdowns}
                    onRemoveBreakdown={removeBreakdown}
                    onSubmit={addBreakdown}
                  />
                ) : (
                  <Column sx={{ alignItems: "center", pt: 24, px: 4, flex: 1, overflowY: "auto" }}>
                    <Image src={ErrorSrc} sx={{ mb: 4, maxWidth: "200px" }} />
                    <Text sx={{ fontWeight: "bold", fontSize: 2, textAlign: "center", mb: 2 }}>Error</Text>
                    <Text sx={{ color: "base.4", fontWeight: "bold", fontSize: 0, textAlign: "center", mb: 2 }}>
                      Update your query and try again
                    </Text>

                    <Button variant="secondary" onClick={() => setBreakdownColumns([])}>
                      Reset
                    </Button>
                  </Column>
                ))}
            </Column>
          </Column>
        </Row>
        <Permission
          permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Update], resource_id: audience?.id }]}
        >
          <Row
            sx={{
              p: 5,
              justifyContent: appFullScreenQueryBuilder ? "space-between" : "end",
              borderTop: "small",
              borderBottomRightRadius: 3,
              borderBottomLeftRadius: 3,
              bg: "base.1",
            }}
          >
            {appFullScreenQueryBuilder && (
              <Button
                aria-label={`${fullscreen ? "Close" : "Open"} full screen query builder`}
                sx={{ bg: "white" }}
                variant="secondary"
                onClick={toggleFullscreen}
              >
                {fullscreen ? <ResizeContractIcon color="base.4" size={17} /> : <ResizeExpandIcon color="base.4" size={17} />}
              </Button>
            )}
            {onSave && (
              <Button loading={saveLoading} sx={{ mr: fullscreen ? 18 : 0 }} onClick={handleSave}>
                {saveLabel || "Save Audience"}
              </Button>
            )}
          </Row>
        </Permission>
      </Column>

      <Modal
        info
        bodySx={{ p: 0 }}
        isOpen={showTransformedSql}
        sx={{ maxWidth: "800px", width: "90%", height: "90%" }}
        title="Transformed SQL"
        onClose={() => setShowTransformedSql(false)}
      >
        <Editor readOnly language="sql" value={transformedSql ?? ""} />
      </Modal>
    </OverlayWrapper>
  );
};

const OverlayWrapper: FC<OverlayProps> = ({ children, isOpen, ...props }) => {
  if (!isOpen) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <Overlay isOpen {...props}>
      {children}
    </Overlay>
  );
};

function toOrConditions(conditions: Condition[] | undefined): OrCondition[] | undefined {
  if (!conditions) {
    return undefined;
  }

  return conditions.map((condition) => {
    if (condition.type !== ConditionType.Or) {
      return {
        type: ConditionType.Or,
        conditions: [condition],
      };
    }
    return condition;
  });
}
