import { FC, ReactElement } from "react";

import { Radio as ThemedRadio, Label, Flex, Text } from "theme-ui";

import { usePermission } from "src/contexts/permission-context";

export interface RadioProps {
  value: boolean;
  label: string | ReactElement;
  description?: string;
  onChange: () => void;
  size?: string;
  disabled?: boolean;
}

export const Radio: FC<Readonly<RadioProps>> = ({ value, label, description, onChange, size, ...props }) => {
  const permission = usePermission();

  const disabled = permission?.unauthorized || props.disabled;

  return (
    <Label
      sx={{
        display: "flex",
        alignItems: description ? "flex-start" : "center",
        cursor: disabled ? undefined : "pointer",
        borderRadius: 1,
        color: disabled ? "base.2" : value ? "grass" : "base.4",
        bg: value ? "avocado" : undefined,
        p: 2,
        height: size === "small" ? "32px" : undefined,
        width: "100%",
        ":hover": {
          bg: disabled ? undefined : "gray.100",
        },
        flexShrink: 0,
        pointerEvents: disabled || value ? "none" : "auto",
        "*": {
          fill: "forest",
        },
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!disabled) {
          onChange();
        }
      }}
    >
      <ThemedRadio readOnly checked={value} disabled={disabled} />

      <Flex sx={{ flexDirection: "column" }}>
        <Text
          sx={{
            fontWeight: size === "small" ? "semi" : "bold",
            color: value && disabled ? "base.7" : value ? "forest" : disabled ? "base.2" : "base.7",
          }}
        >
          {label}
        </Text>
        {description && (
          <Text sx={{ whiteSpace: "pre-wrap", fontWeight: "body", mt: 1 }} variant="subtle">
            {description}
          </Text>
        )}
      </Flex>
    </Label>
  );
};
