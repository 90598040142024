import { FC } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { Box } from "../box";

export interface LogoProps {
  size?: string;
  color?: string;
  sx?: ThemeUIStyleObject;
}

export const Logo: FC<Readonly<LogoProps>> = ({ size = "30px", sx = {} }) => (
  <Box sx={{ width: size, ...sx }}>
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.66052 0.754883H22.7937C23.0206 0.754883 23.2042 0.932797 23.2042 1.15281V15.8216C23.2042 16.0417 23.0206 16.2196 22.7937 16.2196H7.25V1.15281C7.25 0.934157 7.43354 0.754883 7.66052 0.754883Z"
        fill="#CAF23A"
      />
      <path
        d="M0.410519 16.2185H7.24923V22.8474C7.24923 23.0674 7.06568 23.2453 6.83871 23.2453H0.410519C0.183542 23.2453 0 23.0674 0 22.8474V16.6164C0 16.3964 0.183542 16.2185 0.410519 16.2185Z"
        fill="#4FC26B"
      />
    </svg>
  </Box>
);
