import { FC, useState } from "react";

import { ClipboardIcon } from "@heroicons/react/24/outline";
import { Button, FormField, Switch, IconButton, Box } from "@hightouchio/ui/dist";
import * as Sentry from "@sentry/react";
import { useToasts } from "react-toast-notifications2";
import { Text, Grid } from "theme-ui";
import { useClipboard } from "use-clipboard-copy";

import { Permission } from "src/components/permission";
import { Settings } from "src/components/settings";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  DeleteSsoJoinableWorkspaceGroupsMutation,
  InsertSsoJoinableWorkspaceGroupsMutation,
  ResourcePermissionGrant,
  useConfigureSsoMutation,
  useDeleteSsoGroupRolesMutation,
  useDeleteSsoJoinableWorkspaceGroupsMutation,
  useInsertSsoGroupRolesMutation,
  useInsertSsoJoinableWorkspaceGroupsMutation,
  useUpdateOrganizationMutation,
  useWorkspaceQuery,
  useWorkspacesOrganizationsGroupsQuery,
  WorkspacesOrganizationsGroupsQuery,
} from "src/graphql";
import { Badge } from "src/ui/badge";
import { Column, Container, Row } from "src/ui/box";
import { Field } from "src/ui/field";
import { FileUploader } from "src/ui/file";
import { Input } from "src/ui/input";
import { Link } from "src/ui/link";
import { Modal } from "src/ui/modal";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";

export const Organization: FC = () => {
  return (
    <Settings route="sso">
      <Container center={false} size="medium" sx={{ mx: "auto" }}>
        <Grid gap={12}>
          <PermissionProvider permissions={[]}>
            <General />
          </PermissionProvider>
        </Grid>
      </Container>
    </Settings>
  );
};

const General: FC = () => {
  const { workspace: _workspace } = useUser();
  const { data: workspaceData, refetch } = useWorkspaceQuery({ workspaceId: _workspace?.id }, { enabled: Boolean(_workspace) });
  const [ssoModalOpen, setSsoModalOpen] = useState(false);
  const { mutateAsync: updateOrganization } = useUpdateOrganizationMutation();

  const organization = workspaceData?.workspaces_by_pk?.organization;

  const isSsoEnabled = (organization?.auth0_connections || []).length > 0;

  const clipboardLoginUrl = useClipboard({
    copiedTimeout: 600,
  });

  const loginUrl = "https://app.hightouch.com/sso/" + organization?.slug;
  const connectionName = organization?.auth0_connections[0]?.name || `${organization?.slug}-1`;
  const toggleAllowingLogin = async (value: boolean) => {
    await updateOrganization({
      id: organization?.id,
      can_invite_users: value,
    });
    await refetch();
  };

  return (
    <Grid gap={8}>
      <Field
        description={
          "Single sign on (SSO) is a security feature available for Business Tier workspaces." + organization?.plan?.sku !==
          "business_tier"
            ? "Contact us to upgrade your plan."
            : ""
        }
        label="Single Sign On"
      >
        <Grid gap={4}>
          <Row gap={4} sx={{ alignItems: "center" }}>
            <Badge variant={isSsoEnabled ? "green" : "yellow"}>{isSsoEnabled ? "Enabled" : "Disabled"}</Badge>
            {organization?.plan?.sku === "business_tier" && (
              <Button variant="secondary" onClick={() => setSsoModalOpen(true)}>
                {isSsoEnabled ? "Update SAML SSO" : "Add SAML SSO"}
              </Button>
            )}
            <AddSsoModal
              close={() => {
                setSsoModalOpen(false);
              }}
              connectionName={connectionName}
              open={ssoModalOpen}
            />
          </Row>
          {isSsoEnabled && (
            <>
              <FormField
                description="By allowing users to be invited, you can invite users via their email address in addition to letting users login via single sign on"
                label="Allow inviting users"
              >
                <Switch isChecked={Boolean(organization?.can_invite_users)} onChange={toggleAllowingLogin} />
              </FormField>
              <FormField label="Copy your Single Sign On login URL">
                <Row sx={{ alignItems: "center", position: "relative" }}>
                  <Input readOnly sx={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }} value={loginUrl} />
                  <Box
                    sx={{
                      position: "absolute",
                      right: 0,
                      width: "40px",
                      bg: "base.2",
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                  >
                    <IconButton aria-label={"copy"} icon={ClipboardIcon} onClick={() => clipboardLoginUrl.copy(loginUrl)} />
                  </Box>
                </Row>
              </FormField>
            </>
          )}
        </Grid>

        <Permission
          permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update], resource_id: _workspace?.id }]}
        >
          <SsoGroupMapping />
        </Permission>
      </Field>
    </Grid>
  );
};

interface AddSsoModalProps {
  open: boolean;
  close: () => void;
  connectionName: string;
}

const AddSsoModal: FC<AddSsoModalProps> = ({ open, close, connectionName }) => {
  const [cert, setCert] = useState("");
  const [signInEndpoint, setSignInEndpoint] = useState("");
  const { addToast } = useToasts();
  const clipboardSamlUrl = useClipboard({
    copiedTimeout: 600,
  });
  const clipboardAudienceUrl = useClipboard({
    copiedTimeout: 600,
  });

  const { mutateAsync: addSso, isLoading } = useConfigureSsoMutation();

  const handleClose = () => {
    close();
  };

  const save = async () => {
    try {
      await addSso({
        details: {
          cert,
          signInEndpoint,
        },
      });
      addToast("SSO configuration updated", { appearance: "success" });
      close();
    } catch (err) {
      addToast("Failed to configure SSO", { appearance: "error" });
    }
  };

  const audienceValue = `urn:auth0:hightouch:${connectionName}`;
  const samlUrl = `https://hightouch.us.auth0.com/login/callback?connection=${connectionName}`;

  return (
    <Modal
      bodySx={{ pb: 6 }}
      footer={
        <>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button disabled={isLoading || !cert || !signInEndpoint} isLoading={isLoading} onClick={save}>
            Save
          </Button>
        </>
      }
      isOpen={open}
      sx={{ maxWidth: "900px", width: "100%" }}
      title="Add SSO Connection"
      onClose={handleClose}
    >
      <Field
        description={
          <Text>
            If you need help, view our docs <Link to="https://hightouch.com/docs/workspace-management/sso">here</Link>
          </Text>
        }
        label="Step 1: Set up your SSO application in your identity provider"
        size="large"
      >
        <Grid gap={4}>
          <Field description="Use the following SAML URL configuration" label="SAML URL">
            <Row sx={{ alignItems: "center", position: "relative" }}>
              <Input readOnly sx={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }} value={samlUrl} />
              <Box
                sx={{
                  position: "absolute",
                  right: 0,
                  width: "40px",
                  bg: "base.2",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                <IconButton aria-label={"copy"} icon={ClipboardIcon} onClick={() => clipboardSamlUrl.copy(samlUrl)} />
              </Box>
            </Row>
          </Field>
          <Field description="Use the following SAML Audience URI" label="SAML Audience URL">
            <Row sx={{ alignItems: "center", position: "relative" }}>
              <Input readOnly sx={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }} value={audienceValue} />
              <Box
                sx={{
                  position: "absolute",
                  right: 0,
                  width: "40px",
                  bg: "base.2",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                <IconButton aria-label={"copy"} icon={ClipboardIcon} onClick={() => clipboardAudienceUrl.copy(audienceValue)} />
              </Box>
            </Row>
          </Field>
          <Field label="Step 2: Provide the details of your SSO application" size="large" sx={{ mt: 8 }}>
            <Field
              description="This is the URL your identity provider (Okta, Azure AD, etc.) provides when completing the configuration of a SAML application."
              label="Identity Provider Single Sign-On URL"
            >
              <Input
                placeholder="Enter your SAML Sign In Endpoint"
                value={signInEndpoint}
                onChange={(endpoint) => {
                  setSignInEndpoint(endpoint);
                }}
              />
            </Field>
          </Field>
          <Field
            description="This is a text file that usually starts with BEGIN CERTIFICATE. Please upload the entire file as provided by your identity provider."
            label="x.509 Certificate"
          >
            <FileUploader
              acceptedFileTypes={[".pem", ".crt", ".cert"]}
              transformation={"string"}
              value={cert}
              onChange={(value) => {
                setCert(value);
              }}
            />
          </Field>
        </Grid>
      </Field>
    </Modal>
  );
};

const SsoGroupMapping: FC = () => {
  const { data, isLoading, refetch } = useWorkspacesOrganizationsGroupsQuery();
  const { workspace } = useUser();

  if (isLoading) {
    // return null because we return null if the user doesn't have an organization,
    // so they will just see a spinner then nothing else.
    return null;
  }

  // we want the organization that this workspace belongs to - we might get multiple back if the user is an admin.
  const organization = data?.organizations?.find((organization) => organization.id === workspace?.organization?.id);

  if (!organization || organization.workspaces.length === 0) {
    return null;
  }

  const hasOrgGroups = organization.sso_groups.length > 0;
  if (!hasOrgGroups) {
    return null;
  }

  return (
    <Column>
      <Column padding={2} sx={{ mt: 8 }}>
        <Section title="Map your SSO groups to Hightouch roles">
          {organization.workspaces.map((workspace) => {
            if (organization.sso_groups.length === 0) {
              return null;
            }
            return (
              <Row key={"workspace-group-" + workspace?.id} sx={{ flex: 1, pb: 8, alignItems: "center" }}>
                <Column sx={{ width: "100px" }}>
                  <Text sx={{ fontWeight: "500" }}>{workspace.name}</Text>
                </Column>
                <Grid columns={[2, "1fr 6fr"]} gap={2} sx={{ alignItems: "center", width: "100%" }}>
                  {organization.sso_groups.map((ssoGroup) => {
                    return (
                      <WorkspaceGroupMapping
                        key={`${workspace?.id}-${ssoGroup.id}`}
                        group={ssoGroup}
                        refetch={refetch}
                        workspace={workspace}
                      />
                    );
                  })}
                </Grid>
              </Row>
            );
          })}
        </Section>
      </Column>

      <Column padding={2} sx={{ mt: 8, gap: 4 }}>
        <Section title="Select which SSO groups can auto-join your workspaces">
          <Grid columns={[2, "1fr 6fr"]} gap={2} sx={{ alignItems: "center", width: "100%" }}>
            {organization.workspaces.map((workspace) => {
              return (
                <WorkspaceAutojoinMapping
                  key={workspace.id}
                  joinableWorkspaces={organization.sso_joinable_workspace_groups}
                  organization={organization}
                  refetch={refetch}
                  workspace={workspace}
                />
              );
            })}
          </Grid>
        </Section>
      </Column>
    </Column>
  );
};

interface WorkspaceAutojoinMappingProps {
  workspace: WorkspacesOrganizationsGroupsQuery["organizations"][0]["workspaces"][0];
  organization: WorkspacesOrganizationsGroupsQuery["organizations"][0];
  joinableWorkspaces: WorkspacesOrganizationsGroupsQuery["organizations"][0]["sso_joinable_workspace_groups"];
  refetch: () => void;
}

const WorkspaceAutojoinMapping: FC<WorkspaceAutojoinMappingProps> = ({ workspace, refetch, organization }) => {
  const { mutateAsync: insertSsoJoinableWorkspaceGroup } = useInsertSsoJoinableWorkspaceGroupsMutation();
  const { mutateAsync: deleteSsoJoinableWorkspaceGroup } = useDeleteSsoJoinableWorkspaceGroupsMutation();
  const { addToast } = useToasts();

  const groups = organization?.sso_groups.map((group) => {
    return {
      label: group.name,
      value: group.id,
    };
  });

  const groupsForWorkspaces = organization.sso_joinable_workspace_groups
    // use `workspace_id` because if you are an admin, you won't have access to the workspace.id object
    // as your SSO isn't materialized into the memberships view.
    .filter((ssoGroup) => ssoGroup.workspace_id === workspace.id)
    .map((ssoGroup) => {
      return {
        label: ssoGroup.sso_group.name,
        value: ssoGroup.sso_group.id,
      };
    });

  const onChange = async (selectedWorkspaces: { label: string; value: string }[]) => {
    const removed = groupsForWorkspaces.filter(
      (workspace) => !selectedWorkspaces?.find((selectedWorkspace) => selectedWorkspace.value === workspace.value),
    );
    const added = selectedWorkspaces?.filter(
      (workspace) => !groupsForWorkspaces.find((selectedWorkspace) => selectedWorkspace.value === workspace.value),
    );

    const queries: Promise<DeleteSsoJoinableWorkspaceGroupsMutation | InsertSsoJoinableWorkspaceGroupsMutation>[] = [];
    removed.forEach((group) => {
      queries.push(
        deleteSsoJoinableWorkspaceGroup({
          where: {
            workspace_id: {
              _eq: workspace.id,
            },
            group_id: {
              _eq: group.value,
            },
          },
        }),
      );
    });

    added.forEach((group) => {
      queries.push(
        insertSsoJoinableWorkspaceGroup({
          objects: {
            group_id: group.value,
            workspace_id: workspace.id,
            organization_id: organization.id,
          },
        }),
      );
    });

    try {
      await Promise.all(queries);
    } catch (err) {
      addToast("Failed to update auto-joinable workspaces", { appearance: "error" });
      Sentry.captureException(err);
    }
    refetch();
  };

  return (
    <>
      <Column>
        <Text sx={{ mr: 4 }}>{workspace.name}</Text>
      </Column>
      <Column>
        <Select isMulti options={groups} placeholder="Select groups" value={groupsForWorkspaces} onChange={onChange} />
      </Column>
    </>
  );
};

interface WorkspaceGroupMappingProps {
  group: WorkspacesOrganizationsGroupsQuery["organizations"][0]["sso_groups"][0];
  refetch: () => void;
  workspace: WorkspacesOrganizationsGroupsQuery["organizations"][0]["workspaces"][0];
}

const WorkspaceGroupMapping: FC<WorkspaceGroupMappingProps> = ({ group, refetch, workspace }) => {
  const { mutateAsync: insertSsoGroupRoles } = useInsertSsoGroupRolesMutation();
  const { mutateAsync: deleteSsoGroupRoles } = useDeleteSsoGroupRolesMutation();

  const roleOptions = workspace.roles.map((role) => {
    return {
      value: role.id,
      label: role.name,
    };
  });

  const existingRoleMapping = workspace.sso_group_roles.find((ssoGroupRole) => ssoGroupRole.sso_group.id === group.id);

  return (
    <>
      <Column sx={{ textAlign: "right" }}>
        <Text sx={{ mr: 4 }}>{group.name}</Text>
      </Column>
      <Column>
        <Select
          options={roleOptions}
          placeholder="Select role"
          value={existingRoleMapping ? { value: existingRoleMapping.role.id, label: existingRoleMapping.role.name } : null}
          onChange={async (value) => {
            const queries: Promise<any>[] = [];

            // if this group is already mapped to a role, we delete the existing mapping.
            if (existingRoleMapping) {
              queries.push(
                deleteSsoGroupRoles({
                  where: {
                    group_id: {
                      _eq: group.id,
                    },
                    role_id: {
                      _eq: existingRoleMapping.role.id,
                    },
                    workspace_id: {
                      _eq: workspace.id,
                    },
                  },
                }),
              );
            }

            // map this group to this role.
            queries.push(
              insertSsoGroupRoles({
                objects: {
                  role_id: value.value,
                  group_id: group.id,
                  workspace_id: workspace.id,
                },
              }),
            );

            await Promise.all(queries);
            refetch();
          }}
        />
      </Column>
    </>
  );
};
