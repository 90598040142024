import { FC } from "react";

import { Outlet, useLocation } from "react-router-dom";
import { Text } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import { Column } from "src/ui/box";
import { Logo } from "src/ui/brand";
import { LogoutIcon } from "src/ui/icons";
import { Link } from "src/ui/link";

export const AuthLayout: FC = () => {
  const location = useLocation();
  const { user } = useUser();

  const isWorkspaces = location.pathname === "/workspaces";

  return (
    <Column sx={{ width: "100vw", height: "100vh" }}>
      {user && (
        <Link
          sx={{
            display: "flex",
            alignItems: "center",
            color: "base.4",
            position: "absolute",
            top: 8,
            right: 8,
            cursor: "pointer",
            zIndex: 2,
          }}
          to={`${import.meta.env.VITE_API_BASE_URL}/auth/logout`}
        >
          <LogoutIcon color="base.4" />
          <Text sx={{ ml: 2, fontWeight: "semi" }}>Logout</Text>
        </Link>
      )}

      <Column sx={{ zIndex: 1, width: "100%", alignItems: "center", mt: isWorkspaces ? 10 : 16, pb: 24 }}>
        {!isWorkspaces && <Logo size="60px" sx={{ mb: 12 }} />}

        <Outlet />
      </Column>
    </Column>
  );
};
