import { useEffect, useState } from "react";

import { Box, Button, FormField, Heading, Select, Text, TextInput, useToast } from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/browser";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Image } from "theme-ui";
import { isPresent } from "ts-extras";
import * as Yup from "yup";

import { Page } from "src/components/layout";
import { SidebarForm } from "src/components/page";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  AudiencesForPriorityListsQuery,
  ResourcePermissionGrant,
  useCreatePriorityListMutation,
  useMinimalParentModelsQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import PlaceholderSrc from "src/ui/table/placeholder.svg";

import { AudienceSelector } from "./audience-selector";
import { DragAndDropEditor } from "./drag-and-drop-editor";

const priorityListResolver = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  parentModelId: Yup.object().nullable().required("Parent model is required"),
  audiences: Yup.array().required("Select at least one audience"),
});

export const CreatePriorityList = () => {
  const { appPriorityLists } = useFlags();
  const { toast } = useToast();
  const navigate = useNavigate();
  const [showAddAudiences, setShowAddAudiences] = useState(false);

  useEffect(() => {
    if (!appPriorityLists) {
      navigate("/audiences");
    }
  }, [appPriorityLists, navigate]);

  const createPriorityList = useCreatePriorityListMutation();

  const minimalParentModelsQuery = useMinimalParentModelsQuery();
  const selectOptions = minimalParentModelsQuery.data?.segments.map(({ id, name }) => ({ label: name, value: id })) ?? [];

  const { control, handleSubmit, watch } = useForm<{
    name: string;
    parentModelId: { label: string; value: number | string };
    audiences: AudiencesForPriorityListsQuery["segments"][0][];
  }>({
    resolver: yupResolver(priorityListResolver),
    defaultValues: {
      name: "",
      parentModelId: undefined,
      audiences: [],
    },
  });

  const { move, replace, remove } = useFieldArray({
    control: control,
    name: "audiences",
  });

  const audiences = watch("audiences");
  const parentModelId = watch("parentModelId.value");

  const savePriorityList = async (data) => {
    try {
      const { createPriorityList: newData } = await createPriorityList.mutateAsync({
        name: data.name,
        parentModelId: data.parentModelId.value.toString(),
        audienceIds: data.audiences.map(({ id }) => id.toString()),
      });

      analytics.track("Priority List Created", {
        priority_list_id: newData?.id,
        parent_model_id: data.parentModelId.value,
        priority_list_name: data.name,
      });

      toast({
        id: "priority-list-creation-toast",
        title: `Priority list "${data.name}" created`,
        variant: "success",
      });

      if (newData?.id !== undefined) {
        navigate(`/audiences/priority-lists/${newData.id}`);
      } else {
        navigate("/audiences/priority-lists");
      }
    } catch (error) {
      toast({
        id: "priority-list-creation-toast",
        title: "Failed to create a priorty list",
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  return (
    <PermissionProvider permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Create] }]}>
      <Page
        crumbs={[
          { label: "Audiences", link: "/audiences" },
          { label: "Priority Lists", link: "/audiences/priority-lists" },
          { label: "Create priority list" },
        ]}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" flexDirection="column" width="100%">
            <Heading size="xl">Create priority list</Heading>
            <Box display="flex" flexDirection="column" my={8}>
              <Box mb={8}>
                <Controller
                  control={control}
                  name="name"
                  render={({ field, fieldState: { error } }) => (
                    <FormField error={error?.message} label="Name">
                      <TextInput {...field} isInvalid={Boolean(error)} placeholder="Name" />
                    </FormField>
                  )}
                />
              </Box>
              <Controller
                control={control}
                name="parentModelId"
                render={({ field, fieldState: { error } }) => (
                  <FormField
                    description="All audiences in the priority list must have the same parent model."
                    error={minimalParentModelsQuery.error?.message || error?.message}
                    label="Parent model"
                  >
                    <Select
                      {...field}
                      isInvalid={Boolean(minimalParentModelsQuery.error?.message || error?.message)}
                      isLoading={minimalParentModelsQuery.isLoading}
                      options={selectOptions}
                      placeholder="Select a parent model"
                      width="sm"
                      onChange={(option) => {
                        replace([]);
                        field.onChange(option);
                      }}
                    />
                  </FormField>
                )}
              />
            </Box>

            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Heading>Set priority</Heading>
              <Button isDisabled={!watch("parentModelId.value")} onClick={() => setShowAddAudiences(true)}>
                Add audiences
              </Button>
            </Box>
            {audiences?.length > 0 ? (
              <>
                <DragAndDropEditor data={audiences} onMove={move} onRemove={remove} />
              </>
            ) : (
              <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center" mt={8}>
                <Image src={PlaceholderSrc} sx={{ mb: 4 }} />
                <Text>Select a parent model and add audiences to get started</Text>
              </Box>
            )}
          </Box>

          <SidebarForm
            hideInviteTeammate
            hideSendMessage
            buttons={
              <Button
                isDisabled={audiences.length < 1 || !watch("name") || !isPresent(parentModelId)}
                isLoading={createPriorityList.isLoading}
                variant="primary"
                onClick={handleSubmit(savePriorityList)}
              >
                Save
              </Button>
            }
            docsUrl={`${import.meta.env.VITE_DOCS_URL}/`}
            name="adding a priority list"
          />
        </Box>

        <AudienceSelector
          isOpen={showAddAudiences}
          parentModelId={parentModelId}
          selectedData={audiences}
          onClose={() => setShowAddAudiences(false)}
          onSubmit={(data) => replace(data)}
        />
      </Page>
    </PermissionProvider>
  );
};
