import { FC, ReactNode } from "react";

import { ButtonGroup, Button, Paragraph, useToast } from "@hightouchio/ui";
import pluralize from "pluralize";

import { Modal } from "src/ui/modal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  count: number;
  label: string;
  onDelete: () => Promise<void>;
  loading: boolean;
  disabled?: boolean;
  content?: ReactNode | string;
};

export const BulkDeleteConfirmationModal: FC<Readonly<Props>> = ({
  isOpen,
  onClose,
  count,
  label,
  onDelete,
  loading,
  disabled,
  content,
}) => {
  const { toast } = useToast();

  const pluralizedLabel = pluralize(label, count);

  const handleBulkDelete = async () => {
    try {
      await onDelete();
      toast({
        id: "bulk-delete-resource-toast",
        title: `Deleted ${count} ${pluralizedLabel}`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "bulk-delete-resource-toast",
        title: error.message,
        variant: "error",
      });
    }

    onClose();
  };

  return (
    <Modal
      bodySx={{ bg: "white" }}
      footer={
        <ButtonGroup>
          <Button onClick={onClose}>Cancel</Button>
          <Button isDisabled={disabled} isLoading={loading} variant="danger" onClick={handleBulkDelete}>
            Delete
          </Button>
        </ButtonGroup>
      }
      isOpen={isOpen}
      sx={{ width: "500px" }}
      title={`Delete ${count} ${pluralizedLabel}`}
      onClose={onClose}
    >
      {content || (
        <Paragraph>
          Are you sure you want to delete {count} {pluralizedLabel}? You won't be able to undo this.
        </Paragraph>
      )}
    </Modal>
  );
};
