import { FC, ReactNode, useId } from "react";

import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Avatar, IconButton, Link, Spinner } from "@hightouchio/ui";
import { noop } from "lodash";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Text, ThemeUICSSObject } from "theme-ui";

import { SyncsCell } from "src/components/syncs/syncs-cell";
import { AudiencesForPriorityListsQuery } from "src/graphql";
import { Box, Row } from "src/ui/box";
import { SixDots } from "src/ui/icons";
import { useDestinations } from "src/utils/destinations";
import { formatDate } from "src/utils/time";

type ListItemProps = {
  children: ReactNode;
  id: string;
  index: number;
  readOnly?: boolean;
};

const ListItem: FC<Readonly<ListItemProps>> = ({ children, id, index, readOnly }) => {
  return (
    <Draggable draggableId={id} index={index} isDragDisabled={readOnly}>
      {({ dragHandleProps, draggableProps, innerRef }, snapshot) => (
        <Row
          ref={innerRef}
          {...draggableProps}
          {...dragHandleProps}
          style={{
            ...draggableProps.style,
            alignItems: "center",
            borderRadius: "8px",
            marginBottom: "12px",
          }}
        >
          <Box
            sx={{
              flex: 1,
              transition: "box-shadow ease-in 0.2s",
              borderRadius: "inherit",
              boxShadow:
                snapshot.isDragging && !snapshot.isDropAnimating
                  ? "0px 12px 16px rgba(16, 24, 40, 0.16), 0px 8px 16px rgba(16, 24, 40, 0.16), 0px 0px 12px rgba(16, 24, 40, 0.08)"
                  : "none",
            }}
          >
            {children}
          </Box>
        </Row>
      )}
    </Draggable>
  );
};

const headerStyles: ThemeUICSSObject = {
  fontSize: 0,
  letterSpacing: "0.5px",
  fontWeight: 500,
  textTransform: "uppercase",
};

type DragAndDropListProps = {
  data: AudiencesForPriorityListsQuery["segments"][0][]; // TODO: generalize this component to use anything in the future
  isLoading?: boolean;
  readOnly?: boolean;
  onMove(startIndex: number, endIndex: number): void;
  onRemove(index: number): void;
};

export const DragAndDropEditor: FC<Readonly<DragAndDropListProps>> = ({ data, isLoading, readOnly, onMove, onRemove }) => {
  const {
    data: { definitions: destinationDefinitions },
  } = useDestinations();

  const id = useId();

  const gridTemplateColumns = `50px repeat(4, 1fr) 50px`;

  if (isLoading) {
    return (
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Spinner />
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%", py: 3, mb: 4, borderRadius: 3 }}>
      <DragDropContext
        onDragEnd={(result) => {
          if (readOnly) {
            return;
          }

          const { destination, source } = result;
          if (!destination || !source || destination.index === source.index) {
            return;
          }

          onMove(source.index, destination.index);
        }}
      >
        <>
          <Grid
            sx={{
              p: 3,
              alignItems: "center",
              gridTemplateColumns: `${gridTemplateColumns}`,
              gridGap: 0,
            }}
          >
            <Text />
            <Text sx={headerStyles}>Audience</Text>
            <Text sx={headerStyles}>Size</Text>
            <Text sx={headerStyles}>Syncs</Text>
            <Text sx={headerStyles}>Last Updated</Text>
          </Grid>
          <Droppable droppableId={id}>
            {({ placeholder, droppableProps, innerRef }) => (
              <Box
                ref={innerRef}
                as="ul"
                {...droppableProps}
                sx={{
                  position: "relative",
                  pl: 0,
                }}
              >
                {data.map((listItem, index) => (
                  <ListItem key={listItem.id} id={listItem.id.toString()} index={index} readOnly={readOnly}>
                    <Grid
                      as="li"
                      sx={{
                        width: "100%",
                        p: 2,
                        bg: "white",
                        alignItems: "center",
                        borderRadius: 2,
                        gridTemplateColumns,
                        gridGap: 0,
                        border: "small",
                      }}
                    >
                      <Row
                        role="handle"
                        sx={{
                          color: "base.4",
                          button: {
                            ":hover": { cursor: "grab" },
                            ":active": { cursor: "active" },
                          },
                        }}
                      >
                        <IconButton aria-label="Click and drag to sort" icon={() => <SixDots size={16} />} onClick={noop} />
                      </Row>
                      <Row>
                        <Link as={RouterLink} href={`/audiences/${listItem.id}`} target="_blank">
                          {listItem.name}
                        </Link>
                      </Row>
                      <Row>{listItem?.query_runs?.[0]?.size ?? "--"}</Row>
                      <Row>
                        <SyncsCell definitions={destinationDefinitions ?? []} syncs={listItem.syncs} />
                      </Row>
                      <Row sx={{ alignItems: "center" }}>
                        <Text sx={{ fontWeight: "semi" }}>{formatDate(listItem?.updated_at || listItem?.created_at)}</Text>
                        <Text sx={{ color: "base.6", mr: 1 }}>&nbsp;by</Text>
                        <Avatar name={listItem?.updated_by_user?.name || listItem?.created_by_user?.name} />
                      </Row>
                      <Row>
                        <IconButton
                          aria-label="Remove list option"
                          icon={XMarkIcon}
                          isDisabled={readOnly}
                          onClick={() => onRemove(index)}
                        />
                      </Row>
                    </Grid>
                  </ListItem>
                ))}

                {placeholder}
              </Box>
            )}
          </Droppable>
        </>
      </DragDropContext>
    </Box>
  );
};
