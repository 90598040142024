import { FC, useEffect, Fragment, ReactNode, useState } from "react";

import { Text, ThemeUICSSObject } from "theme-ui";

import { Column, Row, Container } from "src/ui/box";
import { Button } from "src/ui/button";
import { CheckIcon, ChevronLeftIcon, ChevronRightIcon } from "src/ui/icons";
import { Modal } from "src/ui/modal";

import { Indices } from "../../../../design/indices";

export interface WizardProps {
  title: string;
  step: number;
  steps: Step[];
  setStep: (step: number) => void;
  previousDisabled?: boolean;
  sx?: ThemeUICSSObject;
  onCancel: () => void;
  onSubmit: () => void;
}

export type Step = {
  title: string;
  header?: ReactNode;
  render: FC;
  loading?: boolean;
  submitting?: boolean;
  onContinue?: () => void;
  continueProps?: any;
  continue?: ReactNode;
  actions?: ReactNode;
  disabled?: boolean;
  contain?: boolean; // True when children content should scroll instead of entire page
};

const size = "medium";

export const Wizard: FC<Readonly<WizardProps>> = ({ title, step, steps, setStep, previousDisabled, onCancel, onSubmit }) => {
  const data = steps[step];
  const [submitting, setSubmitting] = useState(false);
  const [exiting, setExiting] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [step]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Esc" || event.key === "Escape") {
        setExiting(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown, false);
    return () => {
      window.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [setExiting]);

  const isLastStep = step === steps.length - 1;

  return (
    <>
      <Column
        sx={{ position: "absolute", top: 0, left: 0, bg: "white", width: "100vw", minHeight: "100vh", zIndex: Indices.Modal }}
      >
        <Row
          sx={{
            height: "64px",
            bg: "white",
            zIndex: Indices.Content,
            justifyContent: "center",
            width: "100%",
            borderBottom: "small",
            position: "sticky",
            top: 0,
          }}
        >
          <Row sx={{ alignItems: "center", flex: 1, px: 8 }}>
            <Text sx={{ fontSize: 2, fontWeight: "bold", flexShrink: 0 }}>{title}</Text>
            <Row sx={{ flex: 1, alignItems: "center", justifyContent: "center", mx: 8 }}>
              {steps.map(({ title }, i) => {
                const visited = i < step;
                const completed = i < step;
                const current = i === step;
                return (
                  <Fragment key={i}>
                    <Row
                      sx={{
                        alignItems: "center",
                        cursor: completed ? "pointer" : undefined,
                        pointerEvents: previousDisabled ? "none" : undefined,
                      }}
                      onClick={() => {
                        if (completed) {
                          setStep(i);
                        }
                      }}
                    >
                      <Row
                        sx={{
                          flexShrink: 0,
                          alignItems: "center",
                          justifyContent: "center",
                          borderColor: "secondary",
                          border: visited ? "none" : "1px solid",
                          bg: visited ? "base.5" : current ? "secondary" : "white",
                          width: "14px",
                          height: "14px",
                          borderRadius: "7px",
                        }}
                      >
                        {i < step && <CheckIcon color="white" size={11} strokeWidth="1px" />}
                      </Row>
                      <Text
                        sx={{
                          ml: 2,
                          fontWeight: "bold",
                          color: visited ? "base.5" : "secondary",
                          flexShrink: 0,
                          display: ["none", "none", "block", "block"],
                        }}
                      >
                        {title}
                      </Text>
                    </Row>

                    {i < steps?.length - 1 && <Row sx={{ height: "1px", bg: "base.3", flex: 1, mx: 4, maxWidth: "48px" }} />}
                  </Fragment>
                );
              })}
            </Row>

            <Button variant="secondary" onClick={() => setExiting(true)}>
              Exit
            </Button>
          </Row>
        </Row>
        <Column sx={{ position: "sticky", top: "64px", bg: "white", zIndex: Indices.Content }}>
          {data?.header && (
            <Row
              sx={{
                width: "100%",
                height: "80px",
                bg: "base.1",
                zIndex: Indices.Content,
                justifyContent: "center",
                position: "sticky",
              }}
            >
              <Container size={size} sx={{ px: 24 }}>
                <Row sx={{ alignItems: "center", flex: 1 }}>{data?.header}</Row>
              </Container>
            </Row>
          )}
        </Column>

        <Container size={size} sx={{ px: 24, py: 8 }}>
          <Column sx={{ flex: 1, ...(data?.contain ? { overflow: "hidden", maxHeight: "calc(100vh - 216px)" } : {}) }}>
            {data?.render({})}
          </Column>
        </Container>
        <Row
          sx={{
            flexShrink: 0,
            position: "sticky",
            bottom: 0,
            bg: "white",
            height: "80px",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <Row
            sx={{
              justifyContent: "center",
              alignItems: "center",
              height: "80px",
              bg: "white",
              width: "100%",
              borderTop: "small",
            }}
          >
            <Container size={size} sx={{ px: 24 }}>
              <Row sx={{ justifyContent: "space-between", alignItems: "center", flex: 1 }}>
                <Row sx={{ alignItems: "center" }}>
                  {step > 0 && (
                    <Button size="large" variant="secondary" onClick={() => setStep(step - 1)}>
                      <ChevronLeftIcon color="inherit" />
                      Go back
                    </Button>
                  )}
                  {data?.actions || null}
                </Row>
                {data?.continue ? (
                  <Text sx={{ fontSize: 2, fontWeight: "bold", color: "base.4" }}>{data?.continue}</Text>
                ) : (
                  <Button
                    {...(data?.continueProps || {})}
                    disabled={data?.disabled}
                    loading={data?.submitting || submitting}
                    size="large"
                    onClick={async () => {
                      setSubmitting(true);
                      if (isLastStep) {
                        await onSubmit();
                      }
                      if (data?.onContinue) {
                        await data?.onContinue();
                      } else if (!isLastStep) {
                        setStep(step + 1);
                      }
                      setSubmitting(false);
                    }}
                  >
                    {isLastStep ? (
                      "Finish"
                    ) : (
                      <>
                        Continue
                        <ChevronRightIcon color="white" />
                      </>
                    )}
                  </Button>
                )}
              </Row>
            </Container>
          </Row>
        </Row>
      </Column>
      <Modal
        footer={
          <>
            <Button variant="secondary" onClick={() => setExiting(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                setExiting(false);
                onCancel();
              }}
            >
              Exit
            </Button>
          </>
        }
        isOpen={exiting}
        title="Confirm exit"
        onClose={() => setExiting(false)}
      >
        <Text>Are you sure you want to exit? Progress will be lost.</Text>
      </Modal>
    </>
  );
};
