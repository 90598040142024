import { Buffer } from "buffer";

import { useEffect, useMemo, useState } from "react";

import { useToasts } from "react-toast-notifications2";
import { Text, Image } from "theme-ui";

import { SidebarForm } from "src/components/page";
import { SourceForm } from "src/components/sources/forms/form";
import { SourceCatalog } from "src/components/sources/source-catalog";
import { TestNewSourceButton, TestResult, TestSourceBadge, TestUpdatedSourceButton } from "src/components/sources/test-source";
import { DocsLink, PlannerDatabase } from "src/components/sources/warehouse-planning";
import { useUser } from "src/contexts/user-context";
import {
  SourceDefinition,
  useSampleDataSourceDefinitionsQuery,
  useSetupWarehousePlanningWithCredentialsMutation,
  useSourceDefinitionsQuery,
  useCreateSourceV2Mutation,
  useUpdateSourceV2Mutation,
  useFormkitSourceDefinitionQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Row, Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field, FieldError } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { Input } from "src/ui/input";
import { Message } from "src/ui/message";
import { Step } from "src/ui/wizard/wizard";
import { ResourceType, useResourceName } from "src/utils/slug";
import { useSource } from "src/utils/sources";
import { useQueryString } from "src/utils/use-query-string";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

export type NewSource = { id?: string; definition: SourceDefinition | undefined };

type CreateSourceProps = {
  initialStep?: number;
  previousSourceDefinition?: SourceDefinition;
  onSubmit?: (args: NewSource) => void;
  onConnectClick?(defintion: SourceDefinition): void;
  isOnboarding?: boolean;
};

export const useCreateSourceWizard = ({
  initialStep = 0,
  previousSourceDefinition,
  onSubmit,
  onConnectClick,
  isOnboarding = false,
}: Readonly<CreateSourceProps>) => {
  const { addToast } = useToasts();
  const {
    data: { source: sourceFromQueryString },
  } = useQueryString();
  const { user } = useUser();
  const { name, slug, setName } = useResourceName(ResourceType.Source);
  //Selected source (from Step 1) or matching source from ID
  const [sourceDefinition, setSourceDefinition] = useState<SourceDefinition>();
  const [config, setConfig] = useState<any>();
  const [tunnel, setTunnel] = useState<any>();
  const [credentialId, setCredentialId] = useState<string>();
  const [enabledWarehousePlanning, setEnabledWarehousePlanning] = useState<boolean>(false);
  const [plannerDatabase, setPlannerDatabase] = useState<string>();
  const [enabledError, setEnabledError] = useState<Error | null>();

  const [testResult, setTestResult] = useState<TestResult>(TestResult.Unknown);
  const [testing, setTesting] = useState<boolean>(false);
  const [testError, setTestError] = useState<Error | null>(null);
  const [step, setStep] = useWizardStepper(initialStep);

  const {
    data: { id: oAuthId, onboardingSourceId }, //IDs would exist for sources that are authenticated by oauth, yet can still be incomplete.
  } = useQueryString();

  const id = oAuthId ?? onboardingSourceId;

  // Retrieve the source if already exists (Steps 2 and 3)
  const { data: existingOauthSource } = useSource(id ?? "", {
    pause: !id,
  });
  const matchingSourceConfig = existingOauthSource?.config;
  const matchingSourceDefinition = existingOauthSource?.definition;

  const { mutateAsync: createSource, isLoading: creating } = useCreateSourceV2Mutation();
  const { mutateAsync: updateSource, isLoading: updating } = useUpdateSourceV2Mutation();
  const {
    mutateAsync: setupWarehousePlanning,
    isLoading: setupWarehousePlanningLoading,
    error: setupWarehousePlanningError,
  } = useSetupWarehousePlanningWithCredentialsMutation();

  const { data: sourceDefinitionsData, isLoading: loadingCatalog } = useSourceDefinitionsQuery();
  const sourceDefinitions = sourceDefinitionsData?.getSourceDefinitions;

  const { data: sampleDataSourcesData, isLoading: loadingSampleDataSources } = useSampleDataSourceDefinitionsQuery();
  const sampleDataSources = sampleDataSourcesData?.getSampleDataSourceDefinitions;

  const { data: formkitMethods } = useFormkitSourceDefinitionQuery(
    { type: sourceDefinition?.type || "" },
    { enabled: Boolean(sourceDefinition), select: (data) => data.formkitSourceDefinition },
  );

  const isOAuth = useMemo(() => {
    if (Array.isArray(formkitMethods)) {
      if (formkitMethods.length === 1) {
        return formkitMethods[0]?.method === "oauth";
      }
      return formkitMethods.find((s) => config?.methodKey === s.key)?.method === "oauth";
    }
    return false;
  }, [formkitMethods]);

  //Set source, if found by possibly existing ID.
  useEffect(() => {
    if (matchingSourceConfig && matchingSourceDefinition) {
      setConfig(matchingSourceConfig);
      setSourceDefinition(matchingSourceDefinition);
      setStep(1);
    }
  }, [id, Boolean(matchingSourceConfig), Boolean(matchingSourceDefinition)]);

  useEffect(() => {
    if (testResult !== TestResult.Unknown) {
      analytics.track("Source Config Tested", {
        test_successful: testResult === TestResult.Success,
        error_message: `${testError}`,
      });
    }
  }, [testResult]);

  useEffect(() => {
    if (step === 2) {
      analytics.track("Source Slug Screen Viewed", {
        source_type: sourceDefinition?.type ?? "",
      });
    }
  }, [step]);

  useEffect(() => {
    if (sourceFromQueryString && !sourceDefinition) {
      const decodeSource = JSON.parse(Buffer.from(sourceFromQueryString, "base64").toString());
      if (decodeSource.type === "googlesheets") {
        setSourceDefinition(sourceDefinitions?.find((def) => def.type === "googlesheets"));
      }
      setStep(1);
    }
  }, [sourceDefinitions, sourceFromQueryString]);

  // move to second step if source definition is provided
  useEffect(() => {
    if (previousSourceDefinition) {
      setSourceDefinition(previousSourceDefinition);
      if (previousSourceDefinition.isSampleDataSource) {
        setConfig({});
      }
      setStep(1);
    }
  }, [previousSourceDefinition]);

  // Set the name and slug of the source automatically
  useEffect(() => {
    if (sourceDefinition?.name) {
      setName(sourceDefinition.name);
    }
  }, [sourceDefinition?.name]);

  const create = async () => {
    let createdId: string | undefined;

    if (id) {
      const { updateSourceWithSecrets } = await updateSource({
        id: String(id),
        object: {
          config,
          name,
          slug,
          setup_complete: true,
        },
      });
      createdId = updateSourceWithSecrets?.id.toString();
    } else {
      const { createSourceWithSecrets } = await createSource({
        object: {
          name,
          slug,
          config,
          type: sourceDefinition?.isSampleDataSource ? "sample-data" : sourceDefinition?.type,
          setup_complete: true,
          created_by: user?.id != null ? String(user?.id) : undefined,
          tunnel_id: tunnel?.id,
          credential_id: credentialId ? String(credentialId) : undefined,
          plan_in_warehouse: enabledWarehousePlanning,
          sample_data_source_id: sourceDefinition?.isSampleDataSource ? sourceDefinition.type : null,
          plan_in_warehouse_config: {
            plannerDatabase,
          },
        },
      });
      createdId = createSourceWithSecrets?.id.toString();
    }

    addToast(`Source ${name} created!`, {
      appearance: "success",
    });

    analytics.track("Source Created", {
      source_name: name,
      source_type: sourceDefinition?.type ?? "",
    });

    onSubmit?.({ id: createdId, definition: sourceDefinition });
  };

  const skipFinalStep = isOnboarding && !sourceDefinition?.isSampleDataSource;

  const steps: Step[] = [
    {
      title: "Select source",
      continue: isOnboarding ? undefined : "Click on a source to continue",
      header: (
        <Row
          sx={{
            alignItems: "center",
            gap: 4,
          }}
        >
          <Heading>Select a data source</Heading>
        </Row>
      ),
      render: () => (
        <Column sx={{ gap: 8 }}>
          {sampleDataSources && sourceDefinitions && (
            <SourceCatalog
              sampleDataSources={sampleDataSources}
              selection={sourceDefinition}
              sourceDefinitions={sourceDefinitions}
              onSelect={(source) => {
                setSourceDefinition(source);
                if (source?.isSampleDataSource) {
                  setConfig({});
                }
                if (!isOnboarding) {
                  setStep((step) => step + 1);
                }
              }}
            />
          )}
        </Column>
      ),
    },
  ];

  if (!sourceDefinition?.isSampleDataSource) {
    steps.push({
      title: `Connect source`,
      submitting: skipFinalStep && (creating || updating),
      continue: isOAuth && !id ? "Connect with OAuth to continue" : undefined,
      continueProps: { form: "source-form", type: "submit" },
      onContinue: !skipFinalStep
        ? () => {
            // empty function needed to set step after submit is triggered
          }
        : undefined,
      header: (
        <Row sx={{ alignItems: "center", gap: 4 }}>
          <Image src={sourceDefinition?.icon} sx={{ width: "32px", objectFit: "contain" }} />
          <Heading>Connect to {sourceDefinition?.name}</Heading>
          <TestSourceBadge key={0} result={testResult} testing={testing} />
        </Row>
      ),
      render: () => {
        if (!sourceDefinition) {
          return null;
        }

        return (
          <Row sx={{ alignItems: "flex-start", gap: 8 }}>
            <Column sx={{ flexGrow: 1, gap: 8 }}>
              <SourceForm
                config={config}
                credentialId={credentialId}
                definition={sourceDefinition}
                disableAuthMethod={Boolean(id)}
                error={testError}
                isSetup={true}
                setConfig={setConfig}
                setCredentialId={setCredentialId}
                setTunnel={setTunnel}
                sourceId={id}
                tunnel={tunnel}
                onConnectClick={onConnectClick}
                onSubmit={() => {
                  setStep(step + 1);
                  return Promise.resolve();
                }}
              />
            </Column>
            <SidebarForm
              buttons={
                !id ? (
                  <TestNewSourceButton
                    configuration={config}
                    credentialId={credentialId}
                    definition={sourceDefinition}
                    tunnelId={tunnel?.id}
                    onError={setTestError}
                    onResult={setTestResult}
                  />
                ) : (
                  <TestUpdatedSourceButton
                    buttonProps={{ width: "100%" }}
                    credentialId={credentialId}
                    newConfiguration={config}
                    sourceId={id}
                    tunnelId={tunnel?.id}
                    onError={setTestError}
                    onLoading={setTesting}
                    onResult={setTestResult}
                  />
                )
              }
              docsUrl={sourceDefinition?.docs ?? ""}
              name={sourceDefinition?.name ?? ""}
            />
          </Row>
        );
      },
    });
  }

  if (!skipFinalStep) {
    steps.push({
      title: "Finalize source",
      submitting: creating || updating,
      disabled: !name,
      header: <Heading>Finalize settings for this source</Heading>,
      render: () => (
        <Column sx={{ gap: 16, maxWidth: "600px" }}>
          <Field
            description="Including details about the source's environment (prod/dev), data contents, and owners"
            label="Source name"
          >
            <Input value={name} onChange={(value) => setName(value)} />
          </Field>
          {sourceDefinition?.supportsInWarehouseDiffing && (
            <Column>
              <Heading variant="h4">Would you like to enable warehouse planning?</Heading>
              <Text sx={{ my: 4, color: "base.7" }}>
                Hightouch tracks incremental changes in your data model (such as added, changed, or removed rows) and syncs only
                those records. Warehouse planning is an advanced feature that improves sync speed by performing this computation
                in your data warehouse.
              </Text>
              {setupWarehousePlanningError ? (
                <Column>
                  <Message variant="error">
                    Failed to enable warehouse planning. This error is most likely caused by insufficient permissions to write
                    data back to your warehouse. Read our <DocsLink>docs</DocsLink> for instructions.
                  </Message>
                  <FieldError error={enabledError} sx={{ mb: 2 }} />
                </Column>
              ) : (
                <Message>
                  To enable this feature, you must grant Hightouch permission to write data back to your warehouse. Read our{" "}
                  <DocsLink>docs</DocsLink> for instructions.
                </Message>
              )}
              {sourceDefinition.supportsCrossDbReference && (
                <PlannerDatabase plannerDatabase={plannerDatabase} onChange={setPlannerDatabase} />
              )}
              <Button
                disabled={enabledWarehousePlanning}
                loading={setupWarehousePlanningLoading}
                sx={{ mt: 4, width: "180px" }}
                variant="secondary"
                onClick={() => {
                  setupWarehousePlanning(
                    {
                      type: sourceDefinition?.type,
                      config: JSON.stringify(config),
                      plannerDatabase: plannerDatabase,

                      // @ts-expect-error credentialId should be string in GQL
                      credentialId: credentialId as number,

                      tunnelId: tunnel?.id,
                    },

                    {
                      onSuccess: () => {
                        setEnabledWarehousePlanning(true);
                      },
                      onError: (error) => {
                        setEnabledError(error);
                      },
                    },
                  );
                }}
              >
                {enabledWarehousePlanning ? "Successfully enabled!" : "Enable warehouse planning"}
              </Button>
            </Column>
          )}
        </Column>
      ),
    });
  }

  return {
    createSource: create,
    loading: loadingCatalog || loadingSampleDataSources,
    sourceDefinition,
    step,
    steps,
    setStep,
    id: id,
  };
};
