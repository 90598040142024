import { FC } from "react";

import { Box, Grid, Flex, Text } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import { Circle } from "src/ui/circle";
import { ChevronDownIcon, LogoutIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { Menu } from "src/ui/menu";
import { getInitials } from "src/utils/user";

export const UserMenu: FC = () => {
  const { user } = useUser();

  return (
    <>
      <Menu
        header={
          <Box sx={{ width: "100%" }}>
            <Flex sx={{ justifyContent: "space-between", width: "100%" }}>
              <Box sx={{ mr: 2, overflow: "hidden" }}>
                <Text sx={{ fontSize: 2, fontWeight: "semi", overflow: "hidden", textOverflow: "ellipsis" }}>{user?.name}</Text>
                <Text sx={{ color: "base.5", overflow: "hidden", textOverflow: "ellipsis" }}>{user?.email}</Text>
              </Box>
              <Circle color="avocado" radius="32px" sx={{ color: "forest", fontWeight: "bold", fontSize: 0 }}>
                {getInitials(user?.name)}
              </Circle>
            </Flex>

            <Grid sx={{ gridAutoColumns: "max-content", gridAutoFlow: "column", alignItems: "center", fontSize: 0, mt: 2 }}>
              <Link to={`${import.meta.env.VITE_WEBSITE_URL}/terms-of-service`} variant="subtle">
                Terms
              </Link>
              <Circle color="dark.1" radius="4px" />
              <Link to={`${import.meta.env.VITE_WEBSITE_URL}/privacy-policy`} variant="subtle">
                Privacy
              </Link>
            </Grid>
          </Box>
        }
        options={[
          {
            divider: "top",
            icon: LogoutIcon,
            label: "Log out of Hightouch",
            link: `${import.meta.env.VITE_API_BASE_URL}/auth/logout`,
          },
        ]}
        placement="bottom-end"
        width="300px"
      >
        <Flex sx={{ alignItems: "center" }}>
          <Circle color="avocado" radius="32px" sx={{ color: "forst", fontWeight: "bold", fontSize: 0, mr: 2 }}>
            {getInitials(user?.name)}
          </Circle>
          <Text
            sx={{
              fontSize: 0,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              mr: 2,
              fontWeight: "semi",
              color: "base.5",
            }}
          >
            {user?.email}
          </Text>
          <ChevronDownIcon size={14} />
        </Flex>
      </Menu>
    </>
  );
};
