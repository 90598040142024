import { useEffect, FC, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications2";

import { EditingDraftWarning } from "src/components/drafts/draft-warning";
import { ExploreWithSave } from "src/components/explore/explore-with-save";
import { Page } from "src/components/layout";
import { DraftProvider, useDraft } from "src/contexts/draft-context";
import { useUser } from "src/contexts/user-context";
import { ResourceToPermission, useModelQuery, ModelQuery as ModelGqlQuery } from "src/graphql";
import { PageSpinner } from "src/ui/loading";
import { QueryType, useModelRun, useQueryState, useUpdateQuery } from "src/utils/models";
import { useSource } from "src/utils/sources";

export const ModelQueryWrapper: FC = () => {
  const { model_id: modelId } = useParams<{ model_id: string }>();
  const { workspace } = useUser();
  const { data: modelData, isLoading: modelLoading } = useModelQuery(
    {
      id: modelId ?? "",
    },
    { enabled: Boolean(modelId) },
  );
  const navigate = useNavigate();

  if (modelLoading || !modelData) {
    return <PageSpinner />;
  }

  return (
    <DraftProvider
      initialResourceIsDraft={modelData.segments_by_pk?.draft || false}
      resourceId={modelId}
      resourceType={ResourceToPermission.Model}
      onPublish={() => navigate(`/${workspace?.slug}/models/${modelId}`)}
    >
      <ModelQuery modelData={modelData} modelLoading={modelLoading} />
    </DraftProvider>
  );
};

interface Props {
  modelData: ModelGqlQuery | undefined;
  modelLoading: boolean;
}

const ModelQuery: FC<Props> = ({ modelData, modelLoading }: Props) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { workspace } = useUser();

  const { queryState, initQueryState, setSQL, setDBTModel, setLookerLook, setTable, setCustomQuery, isQueryDefined } =
    useQueryState();

  const {
    draft,
    editingDraft,
    editingDraftChanges,
    setSubmitDraftModalOpen,
    mergeResourceWithDraft,
    setEditingDraft,
    onViewDraft,
  } = useDraft();

  const update = useUpdateQuery();

  const [model, setModel] = useState<ModelGqlQuery["segments_by_pk"]>();
  const type = model?.query_type as QueryType;
  const { data: source, loading: sourceLoading, error: sourceError } = useSource(model?.connection?.id);

  useEffect(() => {
    const model = modelData?.segments_by_pk;
    if (draft && model) {
      const copy = mergeResourceWithDraft(model);
      setModel(copy as ModelGqlQuery["segments_by_pk"]);
    } else {
      setModel(model);
    }
  }, [modelData, editingDraft]);

  const {
    runQuery,
    getSchema,
    cancelQuery,
    resetRunState,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    rawColumns,
  } = useModelRun(type, undefined, {
    modelId: model?.id,
    variables: { sourceId: source?.id, ...queryState },
  });

  const save = async (data) => {
    await update({ model, queryState, columns: data?.columns ?? rawColumns });

    if (workspace?.approvals_required) {
      setSubmitDraftModalOpen(true);
    } else {
      addToast("Query updated!", {
        appearance: "success",
      });
    }
  };

  useEffect(() => {
    initQueryState(model);
  }, [model]);

  if (modelLoading || sourceLoading) {
    return <PageSpinner />;
  }

  const modelId = modelData?.segments_by_pk?.id;

  const link = `/models/${modelId}`;

  return (
    <Page
      crumbs={[
        { label: "Models", link: "/models" },
        {
          label: model?.name ?? "",
          link: editingDraftChanges ? `${link}?editing=true` : link,
        },
        {
          label: "Query",
        },
      ]}
      outsideTopbar={
        draft && (
          <EditingDraftWarning
            draft={draft}
            editingDraft={editingDraft}
            resourceType={ResourceToPermission.Model}
            setEditingDraft={setEditingDraft}
            sx={{ top: 0 }}
            onViewDraft={onViewDraft}
          />
        )
      }
      size="full"
      sx={{ height: "100vh", overflow: "hidden" }}
    >
      <ExploreWithSave
        cancelQuery={cancelQuery}
        columns={columns}
        error={queryError || sourceError?.message}
        errorAtLine={queryErrorAtLine}
        getSchema={getSchema}
        isQueryDefined={isQueryDefined}
        isResultTruncated={Boolean(isResultTruncated)}
        loading={queryLoading || sourceLoading}
        numRowsWithoutLimit={numRowsWithoutLimit}
        reset={resetRunState}
        rows={rows}
        runQuery={runQuery}
        saveLabel="Save"
        source={source}
        type={type}
        onBack={() =>
          navigate({
            pathname: `/models/${modelId}`,
            search: editingDraftChanges ? "?editing=true" : undefined,
          })
        }
        onSave={save}
        {...queryState}
        modelId={model?.id}
        rowsPerPage={15}
        onCustomQueryChange={setCustomQuery}
        onDBTModelChange={setDBTModel}
        onLookerLookChange={setLookerLook}
        onSQLChange={setSQL}
        onTableChange={setTable}
      />
    </Page>
  );
};
