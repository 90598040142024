import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const AudienceIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M12.4049 14.5399C8.9549 14.5399 6.04395 15.1037 6.04395 17.2794C6.04395 19.4561 8.9737 20 12.4049 20C15.8549 20 18.7658 19.4362 18.7658 17.2605C18.7658 15.0839 15.8361 14.5399 12.4049 14.5399Z" />
      <path
        d="M12.4046 12.467C14.7407 12.467 16.6139 10.5831 16.6139 8.23351C16.6139 5.88306 14.7407 4 12.4046 4C10.0685 4 8.19531 5.88306 8.19531 8.23351C8.19531 10.5831 10.0685 12.467 12.4046 12.467Z"
        opacity="0.4"
      />
      <path
        d="M21.5436 9.21926C22.148 6.84179 20.376 4.70657 18.1196 4.70657C17.8743 4.70657 17.6397 4.73359 17.4105 4.77952C17.38 4.78672 17.346 4.80203 17.3281 4.82905C17.3075 4.86327 17.3227 4.9092 17.3451 4.93892C18.0229 5.89531 18.4124 7.05973 18.4124 8.3097C18.4124 9.50744 18.0551 10.6241 17.4284 11.5508C17.3639 11.6463 17.4212 11.775 17.5349 11.7949C17.6925 11.8228 17.8537 11.8372 18.0184 11.8417C19.6615 11.8849 21.1362 10.8213 21.5436 9.21926Z"
        opacity="0.4"
      />
      <path d="M23.2655 14.8169C22.9646 14.1721 22.2384 13.73 21.1344 13.5129C20.6133 13.385 19.203 13.2049 17.8913 13.2292C17.8716 13.2319 17.8608 13.2455 17.859 13.2545C17.8563 13.2671 17.8617 13.2887 17.8877 13.3022C18.4939 13.6039 20.8371 14.916 20.5425 17.6834C20.53 17.8032 20.6258 17.9067 20.7449 17.8887C21.3215 17.8059 22.8052 17.4853 23.2655 16.4866C23.5198 15.9588 23.5198 15.3456 23.2655 14.8169Z" />
      <path
        d="M7.50113 4.77979C7.2728 4.73296 7.03731 4.70685 6.79197 4.70685C4.53556 4.70685 2.76355 6.84207 3.36885 9.21953C3.77536 10.8216 5.25009 11.8852 6.89315 11.842C7.05791 11.8375 7.21997 11.8221 7.37667 11.7951C7.49039 11.7753 7.54769 11.6465 7.48322 11.5511C6.85644 10.6235 6.49917 9.50771 6.49917 8.30997C6.49917 7.0591 6.88957 5.89468 7.56739 4.93919C7.58888 4.90947 7.605 4.86354 7.58351 4.82932C7.5656 4.80141 7.53247 4.787 7.50113 4.77979Z"
        opacity="0.4"
      />
      <path d="M3.77761 13.5127C2.67358 13.7297 1.9483 14.1719 1.64745 14.8167C1.39226 15.3453 1.39226 15.9586 1.64745 16.4872C2.10768 17.485 3.59137 17.8065 4.16801 17.8885C4.28709 17.9065 4.38201 17.8038 4.36947 17.6831C4.07488 14.9166 6.41815 13.6045 7.02524 13.3028C7.05031 13.2884 7.05568 13.2677 7.05299 13.2542C7.0512 13.2452 7.04135 13.2317 7.02165 13.2299C5.70899 13.2047 4.29963 13.3848 3.77761 13.5127Z" />
    </Icon>
  );
};
