import { FC, useEffect } from "react";

import { SearchProvider } from "src/components/search/search-provider";
import { DestinationDefinition } from "src/graphql";
import { PageSpinner } from "src/ui/loading";
import { Wizard } from "src/ui/wizard";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

import { useCreateDestinationWizard } from "../destinations/use-create-destination-wizard";

type Props = {
  definition?: DestinationDefinition;
  destinationSetup: "declaration" | "connect" | "all";
  onCancel(): void;
  onSubmit({ id, definition }: { id?: string; definition: DestinationDefinition }): void;
};

export const OnboardingCreateDestination: FC<Readonly<Props>> = (props) => {
  return (
    <SearchProvider>
      <OnboardingCreateDestinationWithSearch {...props} />
    </SearchProvider>
  );
};

const OnboardingCreateDestinationWithSearch: FC<Readonly<Props>> = ({ definition, destinationSetup, onCancel, onSubmit }) => {
  const [step, setStep] = useWizardStepper(0);

  const {
    createDestination,
    definition: destinationDefinition,
    loading,
    oAuthSuccess,
    steps,
  } = useCreateDestinationWizard({
    destinationDefinition: definition,
    step,
    setStep,
    onConnectClick: (definition) => {
      localStorage.setItem("onboardingOauth", definition.type);
    },
    onSubmit,
    isOnboarding: true,
  });

  const declareDestination = () => {
    destinationDefinition && onSubmit({ definition: destinationDefinition });
  };

  // Skip first step if definition has been set in the declaration
  useEffect(() => {
    if (destinationSetup !== "declaration" && definition && step === 0) {
      setStep(1);
    }
  }, [definition, destinationSetup, setStep, step]);

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <Wizard
      previousDisabled={oAuthSuccess}
      setStep={setStep}
      step={step}
      steps={destinationSetup === "declaration" && steps[0] ? [steps[0]] : steps}
      title="New destination"
      onCancel={onCancel}
      onSubmit={destinationSetup === "declaration" ? declareDestination : createDestination}
    />
  );
};
