import { FC, ReactNode, useEffect, useRef } from "react";

import type { ElementOf } from "ts-essentials";

import { WorkspaceValidation } from "src/components/layout/header/workspace-validation";
import { useHeaderHeight } from "src/contexts/header-height-context";

import { SyncQuery } from "../../../graphql";
import { Breadcrumbs, Crumb } from "./breadcrumbs";
import { ExpiredSyncWarning } from "./expired-sync-warning";
import { Header, Topbar } from "./header";
import { OverageBanner } from "./overage-banner";
import { TrialBanner } from "./trial-banner";
import { UserMenu } from "./user-menu";
import { WorkspaceWarning } from "./workspace-warning";

export type Props = {
  children?: ReactNode;
  crumbs?: Crumb[];
  outsideTopbar?: ReactNode;
  sync?: ElementOf<SyncQuery["syncs"]>;
};

export const PageHeader: FC<Readonly<Props>> = ({ children, crumbs, outsideTopbar, sync }) => {
  const { setHeaderHeight } = useHeaderHeight();
  const headerElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const headerObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setHeaderHeight(entry.contentRect.height);
      });
    });

    if (headerElement.current) {
      headerObserver.observe(headerElement.current);
    }

    return () => {
      headerObserver.disconnect();
    };
  }, []);

  return (
    <Header ref={headerElement}>
      <WorkspaceWarning />
      <WorkspaceValidation />
      <TrialBanner />
      <OverageBanner />
      {sync && <ExpiredSyncWarning sync={sync} />}
      <Topbar>
        {children || (
          <>
            {crumbs && <Breadcrumbs crumbs={crumbs} />}
            <UserMenu />
          </>
        )}
      </Topbar>
      {outsideTopbar}
    </Header>
  );
};
