import { FC, useEffect, useState } from "react";

import { Alert, FormField, MultiSelect, TextInput } from "@hightouchio/ui";
import * as diff from "diff";
import { sortBy } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Grid } from "theme-ui";

import { Diff } from "src/components/diff";
import { Settings } from "src/components/settings";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import { ChangelogQuery, ResourcePermissionGrant, ResourceToPermission, useChangelogQuery } from "src/graphql";
import { Column, Container } from "src/ui/box";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import { formatDatetime } from "src/utils/time";

export const AuditLog: FC = () => {
  return (
    <Settings route="audit-log">
      <Container center={false} size="medium" sx={{ mx: "auto" }}>
        <Grid gap={12}>
          <PermissionProvider
            permissions={[
              {
                grants: [ResourcePermissionGrant.Read],
                resource: ResourceToPermission.Workspace,
              },
            ]}
          >
            <General />
          </PermissionProvider>
        </Grid>
      </Container>
    </Settings>
  );
};

type ChangelogItem = ChangelogQuery["auditLog"]["items"][0];

const General: FC = () => {
  const navigate = useNavigate();
  const { user, workspace } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryUsers = searchParams.get("users");
  const queryResources = searchParams.get("resources");
  const queryResourceId = searchParams.get("resource_id");
  const queryStartDate = searchParams.get("start_date");
  const queryEndDate = searchParams.get("end_date");

  let queryParamFilteredUsers: string[] = [];

  if (queryUsers !== null) {
    try {
      queryParamFilteredUsers = queryUsers?.split(",") || [];
    } catch (e) {
      queryParamFilteredUsers = [];
    }
  }

  let queryParamFilteredResources;
  if (queryResources !== null) {
    try {
      queryParamFilteredResources = queryResources?.split(",") || [];
    } catch (e) {
      queryParamFilteredResources = [];
    }
  }

  const { offset, limit, page, setPage } = useTableConfig({ limit: 10 });
  const [resourceId, setResourceId] = useState<string | null>(queryResourceId);
  const [startDate, setStartDate] = useState<string | null>(queryStartDate);
  const [endDate, setEndDate] = useState<string | null>(queryEndDate);
  const [selectedChangelogItem, setSelectedChangelogItem] = useState<ChangelogItem | null>();
  const [filteredResources, setFilteredResources] = useState<string[]>(queryParamFilteredResources || []);
  const [filteredUsers, setFilteredUsers] = useState<string[]>(queryParamFilteredUsers || []);
  const { data, isFetching } = useChangelogQuery(
    {
      filters: {
        resource_id: resourceId,
        start_date: startDate,
        end_date: endDate,
        user_ids: filteredUsers,
        filtered_resources: filteredResources,
        offset,
      },
    },
    {
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    setSelectedChangelogItem(null);
  }, [data?.auditLog]);

  useEffect(() => {
    setPage(0);
    if (filteredResources?.length > 0) {
      searchParams.set("resources", filteredResources.join(","));
    } else {
      searchParams.delete("resources");
    }

    if (filteredUsers?.length > 0) {
      searchParams.set("users", filteredUsers.join(","));
    } else {
      searchParams.delete("users");
    }

    if (resourceId) {
      searchParams.set("resource_id", resourceId);
    } else {
      searchParams.delete("resource_id");
    }

    if (startDate) {
      searchParams.set("start_date", startDate);
    } else {
      searchParams.delete("start_date");
    }

    if (endDate) {
      searchParams.set("end_date", endDate);
    } else {
      searchParams.delete("end_date");
    }

    setSearchParams(searchParams);
  }, [filteredResources, startDate, resourceId, endDate, filteredUsers]);

  const columns = [
    {
      name: "Date",
      key: "created_at",
      cell: (created_at) => <>{formatDatetime(created_at)}</>,
    },
    {
      name: "Action",
      key: "action",
    },
    {
      name: "User",
      key: "user_name",
    },
    {
      name: "Resource Type",
      key: "resource",
    },
    {
      name: "Resource Name",
      key: "resource_name",
    },
  ];

  const resourceOptions =
    sortBy(data?.auditLog.resources).map((resource) => ({
      label: resource,
      value: resource,
    })) || [];

  if (workspace?.organization?.plan?.sku !== "business_tier" && !user?.is_admin) {
    return (
      <Alert
        actionText="Upgrade plan"
        message="Updgrade to a business plan to access this feature. Audit logs include a searchable history of all actions performed in this workspace, such as changes to syncs and models. You can use these logs to diagnose broken syncs, roll back changes, and monitor workspace security."
        title={"Audit logs are not available in your workspace."}
        variant="warning"
        onAction={() => {
          navigate(`/${workspace?.slug}/settings/billing`);
        }}
      />
    );
  }

  const users = workspace?.all_memberships.filter((m) => m.user).map((m) => m.user) || [];

  const userOptions = sortBy(users, "name")
    .map((user) => {
      return {
        label: user?.name || user?.email,
        value: user?.id.toString(),
      };
    })
    .filter((user) => user !== null) as { label: string; value: string }[];

  return (
    <>
      <Column sx={{ gap: 4 }}>
        {workspace?.organization?.plan?.sku !== "business_tier" && user?.is_admin && (
          <Alert
            message={
              "This feature is only available for Business Tier workspaces, but because you are an admin, you can see this page"
            }
            title="This feature is only available because you are an admin."
            variant="warning"
          />
        )}
        <Grid width={[150, 150, 120]}>
          <FormField label="Filter resources">
            <MultiSelect
              options={resourceOptions}
              placeholder="Resource type"
              value={filteredResources.map((resource) => ({ label: resource, value: resource }))}
              onChange={(options) => setFilteredResources(options.map((option) => option.value))}
            />
          </FormField>
          <FormField label="Performed by">
            <MultiSelect
              options={userOptions}
              placeholder="Select user"
              value={filteredUsers.map((user) => {
                const foundUser = users?.find((m) => m?.id?.toString() === user);
                return { label: foundUser?.name || "", value: user };
              })}
              onChange={(options) => setFilteredUsers(options.map((option) => option.value))}
            />
          </FormField>
          <FormField label="Filter resources by ID">
            <TextInput placeholder="Enter an ID" value={resourceId || ""} onChange={(e) => setResourceId(e.target.value)} />
          </FormField>
          <FormField label="Start date">
            <TextInput type="date" value={startDate || ""} onChange={(e) => setStartDate(e.target.value)} />
          </FormField>
          <FormField label="End date">
            <TextInput placeholder="End date" type="date" value={endDate || ""} onChange={(e) => setEndDate(e.target.value)} />
          </FormField>
        </Grid>
      </Column>
      <Grid columns={["2fr 1fr"]} gap={4}>
        <Column>
          <Table
            columns={columns}
            data={data?.auditLog.items}
            highlight={selectedChangelogItem?.created_at}
            loading={isFetching}
            placeholder={{
              title: "No items in the audit log for your workspace",
              body: "Try changing your filters to see more items",
              error: "Failed to load audit log, please try again",
            }}
            primaryKey="id"
            onRowClick={(row) => {
              setSelectedChangelogItem(row);
            }}
          />
          <Pagination compact count={data?.auditLog.total} label="entries" page={page} rowsPerPage={limit} setPage={setPage} />
        </Column>
        <Column sx={{ borderLeft: "small", pl: 4 }}>
          {selectedChangelogItem ? (
            <ChangelogItemDiff key={selectedChangelogItem.id} item={selectedChangelogItem} />
          ) : (
            "Select an entry from the audit log to view the full change"
          )}
        </Column>
      </Grid>
    </>
  );
};

const ChangelogItemDiff = ({ item }: { item: ChangelogItem }) => {
  if (item.new === null) {
    const diffs = diff.diffJson(item.old, "");
    return <Diff diffs={diffs} />;
  }

  const diffs = diff.diffJson(item.old || "", item.new);
  return <Diff diffs={diffs} />;
};
