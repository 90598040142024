import { useEffect, useMemo, useState } from "react";

import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import {
  Avatar,
  Alert,
  Box,
  Button,
  Heading,
  Link,
  Spinner,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFieldArray, useForm } from "react-hook-form";
import { Link as RouterLink, useParams } from "react-router-dom";
import { isPresent } from "ts-extras";

import { Page } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  AudiencesForPriorityListsQuery,
  ResourcePermissionGrant,
  SegmentsBoolExp,
  SegmentsOrderBy,
  useAudiencesForPriorityListsQuery,
  useDeletePriorityListMutation,
  useObjectQuery,
  usePriorityListQuery,
  useUpdatePriorityListMutation,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";
import { InlineInput } from "src/ui/input";
import { useTableConfig } from "src/ui/table";
import { useNavigate } from "src/utils/navigate";
import { SourceIcon, useSources } from "src/utils/sources";
import { formatDate } from "src/utils/time";

import { AudienceSelector } from "./audience-selector";
import { DragAndDropEditor } from "./drag-and-drop-editor";

type FormData = { audiences: AudiencesForPriorityListsQuery["segments"][0][] };

export const PriorityList = () => {
  const { appPriorityLists } = useFlags();
  const { priority_list_id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { hasPermission: userCanEdit } = useHasPermission([{ resource: "audience", grants: [ResourcePermissionGrant.Update] }]);

  const [showAddAudiences, setShowAddAudiences] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const updatePriorityListMutation = useUpdatePriorityListMutation();
  const deletePriorityListMutation = useDeletePriorityListMutation();

  const priorityListQuery = usePriorityListQuery({ id: priority_list_id ?? "" }, { enabled: Boolean(priority_list_id) });

  const priorityList = priorityListQuery.data?.priority_lists_by_pk;
  const parentModelId = priorityList?.parent_model.id;
  const priorityListAudienceIds = priorityList?.priority_list_memberships?.map(({ segment }) => segment.id.toString());

  const { limit, offset, orderBy } = useTableConfig<SegmentsOrderBy>({
    defaultSortKey: "updated_at",
    limit: 100_000,
  });

  const filters: SegmentsBoolExp = useMemo(() => {
    const hasuraFilters: SegmentsBoolExp = {
      id: { _in: priorityListAudienceIds },
      query_type: { _eq: "visual" },
      visual_query_parent_id: { _eq: parentModelId },
    };

    return hasuraFilters;
  }, [parentModelId, priorityListAudienceIds]);

  // Fetch audiences data to display in priority list
  const audiencesForPriorityListsQuery = useAudiencesForPriorityListsQuery(
    {
      filters,
      limit,
      offset,
      orderBy,
    },
    {
      enabled: isPresent(parentModelId),
    },
  );

  // Get parent model information
  const { data: parentModelData, isLoading: parentModelLoading } = useObjectQuery(
    {
      id: parentModelId ?? "",
    },
    {
      enabled: Boolean(parentModelId),
    },
  );

  // Get parent model's source's information
  const { data: sources } = useSources();

  const parentModel = parentModelData?.segments_by_pk;
  const parentModelSource = sources.find(({ id }) => parentModel?.connection?.id === id);
  const initialAudiences = useMemo(() => {
    const result: AudiencesForPriorityListsQuery["segments"] = [];

    // preserve rank order sent from server
    priorityList?.priority_list_memberships?.forEach(({ segment: { id } }) => {
      const audience = audiencesForPriorityListsQuery.data?.segments.find(({ id: audienceId }) => id === audienceId);
      if (audience) {
        result.push(audience);
      }
    });

    return result;
  }, [priorityList?.priority_list_memberships, audiencesForPriorityListsQuery.data?.segments]);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useForm<FormData>({
    defaultValues: {
      audiences: initialAudiences,
    },
  });

  const { move, remove, replace } = useFieldArray({
    control: control,
    name: "audiences",
  });

  const audiences = watch("audiences");

  const trackPriorityListUpdates = () => {
    analytics.track("Priority List Updated", {
      priority_list_id: priority_list_id,
      parent_model_id: parentModelId,
    });
  };

  const save = async (data: FormData) => {
    if (!userCanEdit) {
      toast({
        id: "update-priority-list-toast",
        title: "You don't have permission to edit this priority list",
        variant: "error",
      });

      return;
    }

    try {
      await updatePriorityListMutation.mutateAsync({
        id: priority_list_id ?? "",
        audienceIds: data.audiences.map(({ id }) => id.toString()),
      });

      trackPriorityListUpdates();

      toast({
        id: "update-priority-list-toast",
        title: "Priority list updated",
        variant: "success",
      });

      priorityListQuery.refetch();
    } catch (error) {
      toast({
        id: "update-priority-list-toast",
        title: "Failed to update this priority list",
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  const saveName = async (name: string) => {
    if (!userCanEdit) {
      toast({
        id: "update-priority-list-toast",
        title: "You don't have permission to edit this priority list",
        variant: "error",
      });

      return;
    }

    try {
      await updatePriorityListMutation.mutateAsync({
        id: priority_list_id ?? "",
        name,
        audienceIds: initialAudiences.map(({ id }) => id.toString()),
      });

      trackPriorityListUpdates();

      toast({
        id: "update-priority-list-toast",
        title: "Priority list updated",
        variant: "success",
      });

      priorityListQuery.refetch();
    } catch (error) {
      toast({
        id: "update-priority-list-toast",
        title: "Failed to update this priority list",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const deletePriorityList = async () => {
    try {
      await deletePriorityListMutation.mutateAsync({
        id: priority_list_id ?? "",
      });

      analytics.track("Priority List Deleted", {
        priority_list_id,
        parent_model_id: priorityList?.parent_model?.id,
        priority_list_name: priorityList?.name,
      });

      // success toast shown in delete confirmation modal

      navigate("/audiences/priority-lists");
    } catch (error) {
      toast({
        id: "delete-model-error",
        title: "Failed to delete this priority list",
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  const resetForm = () => {
    reset({
      audiences: initialAudiences,
    });
  };

  useEffect(() => {
    // update form when priority list from db changes
    reset({
      audiences: initialAudiences,
    });
  }, [priorityList, initialAudiences?.length]);

  const priorityListLoading = priorityListQuery.isLoading || audiencesForPriorityListsQuery.isLoading;

  useEffect(() => {
    if (!appPriorityLists) {
      navigate("/audiences");
    }
  }, [appPriorityLists, navigate]);

  return (
    <PermissionProvider permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Preview] }]}>
      <Page
        crumbs={[
          { label: "Audiences", link: "/audiences" },
          { label: "Priority Lists", link: "/audiences/priority-lists" },
          { label: priorityList?.name ?? "Priority list" },
        ]}
      >
        {priorityListLoading || parentModelLoading ? (
          <Box alignItems="center" display="flex" height="100%" justifyContent="center" width="100%">
            <Spinner />
          </Box>
        ) : (
          <>
            <Box alignItems="flex-end" display="flex" justifyContent="space-between" mb={2}>
              <InlineInput value={priorityList?.name} onChange={saveName} />

              <PermissionProvider permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Delete] }]}>
                <Button
                  isLoading={deletePriorityListMutation.isLoading}
                  variant="warning"
                  onClick={() => setShowDeleteModal(true)}
                >
                  Delete
                </Button>
              </PermissionProvider>
            </Box>
            <Box display="flex" mb={5}>
              <Box alignItems="center" borderRight="1px solid #E9ECF5" display="flex" pr={4}>
                {parentModelSource && (
                  <SourceIcon
                    source={parentModelSource}
                    sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", mr: 2 }}
                  />
                )}
                <Text>{parentModel?.name}</Text>
                <Box height="16px" ml={2} width="16px">
                  <Link as={RouterLink} href="/">
                    <ArrowTopRightOnSquareIcon />
                  </Link>
                </Box>
              </Box>
              <Box alignItems="center" display="flex" mr={1} pl={4}>
                <Box color="gray.600" mr={2}>
                  <Text>Last updated:</Text>
                </Box>
                <Text>{formatDate(priorityList?.updated_at || priorityList?.created_at)}</Text>
                <Box alignItems="center" display="flex" ml={1}>
                  <Box color="gray.600" mr={2}>
                    <Text>by</Text>
                  </Box>
                  <Avatar name={priorityList?.updated_by_user?.name || priorityList?.created_by_user?.name} />
                </Box>
              </Box>
            </Box>

            <Tabs>
              <TabList>
                <Tab>Audiences</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Box display="flex" flex={1} justifyContent="space-between" minHeight={0} mt={6} width="100%">
                    <Box display="flex" flex={1} flexDirection="column" minHeight={0} width="100%">
                      {priorityListQuery.error || audiencesForPriorityListsQuery.error ? (
                        <Box display="flex" flex={1} flexDirection="column" minHeight={0}>
                          <Alert
                            message="There was a problem fetching this priority list"
                            title="There was a problem"
                            variant="error"
                          />
                        </Box>
                      ) : (
                        <>
                          <Box alignItems="center" display="flex" justifyContent="space-between">
                            <Heading>Set priority</Heading>
                            <Button onClick={() => setShowAddAudiences(true)}>Add audiences</Button>
                          </Box>
                          <DragAndDropEditor
                            data={audiences}
                            isLoading={audiencesForPriorityListsQuery.isLoading || priorityListQuery.isRefetching}
                            readOnly={!userCanEdit}
                            onMove={move}
                            onRemove={remove}
                          />
                        </>
                      )}
                    </Box>

                    <SidebarForm
                      hideInviteTeammate
                      hideSendMessage
                      buttons={
                        <>
                          <Permission permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Update] }]}>
                            <Button
                              disabled={!isDirty}
                              isLoading={updatePriorityListMutation.isLoading}
                              variant="primary"
                              onClick={handleSubmit(save)}
                            >
                              Save
                            </Button>
                          </Permission>
                          <Button disabled={!isDirty} onClick={resetForm}>
                            Cancel
                          </Button>
                        </>
                      }
                      docsUrl={`${import.meta.env.VITE_DOCS_URL}/`}
                      name="adding a priority list"
                    />
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>

            <AudienceSelector
              isOpen={showAddAudiences}
              parentModelId={parentModelId}
              selectedData={audiences}
              onClose={() => setShowAddAudiences(false)}
              onSubmit={(data) => replace(data)}
            />

            <DeleteConfirmationModal
              isOpen={showDeleteModal}
              label="priority list"
              onClose={() => {
                setShowDeleteModal(false);
              }}
              onDelete={deletePriorityList}
            />
          </>
        )}
      </Page>
    </PermissionProvider>
  );
};
