import { useEffect, useState, FC } from "react";

import CronGenerator from "cron-time-generator2";
import moment from "moment";
import { Text, Grid } from "theme-ui";

import { useDbtAccountsQuery, useDbtCredentialsQuery, useDbtJobsQuery } from "src/graphql";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { DateTimeSelect } from "src/ui/datetime-select";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Link } from "src/ui/link";
import { NewSelect } from "src/ui/new-select";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { WEEK_DAYS } from "src/utils/constants";
import { validCronExpression } from "src/utils/schedule";

import { Permission } from "../permission";
import { Schedule, ScheduleType, ScheduleIntervalUnit } from "./types";
import { VisualCronExpression } from "./visual-cron-expression";

interface ScheduleOption {
  label: string;
  value: ScheduleType;
  description: string;
}

const getScheduleOptions = (resource: "sync" | "sequence"): ScheduleOption[] => [
  {
    label: "Manual",
    value: ScheduleType.MANUAL,
    description: `Trigger your ${resource} manually in the Hightouch app or using our API`,
  },
  {
    label: "Interval",
    value: ScheduleType.INTERVAL,
    description: `Schedule your ${resource} to run on a set interval (e.g., once per hour)`,
  },
  {
    label: "Custom recurrence",
    value: ScheduleType.CUSTOM,
    description: `Schedule your ${resource} to run on specific days (e.g., Mondays at 9am)`,
  },
  {
    label: "Cron expression",
    value: ScheduleType.CRON,
    description: `Schedule your ${resource} using a cron expression`,
  },
  {
    label: "dbt Cloud",
    value: ScheduleType.DBT_CLOUD,
    description: `Automatically trigger your ${resource} upon completion of a dbt job`,
  },
];

interface IntervalUnitOption {
  label: string;
  value: ScheduleIntervalUnit;
}

const intervalUnitOptions: IntervalUnitOption[] = [
  { label: "Minute(s)", value: "minute" },
  { label: "Hour(s)", value: "hour" },
  { label: "Day(s)", value: "day" },
  { label: "Week(s)", value: "week" },
];

interface Props {
  schedule: Schedule | null;
  setSchedule: (schedule: Schedule) => void;
  resource?: "sync" | "sequence";
}

/**
 * Return a Date for the next whole [unit], e.g. the next hour
 * @param unit
 */
function nextInterval(unit: "second" | "minute" | "hour" | "day" | "week"): Date {
  const date = moment().add(1, unit);

  if (unit === "week") {
    date.days(0).hours(0).minutes(0).seconds(0).milliseconds();
  } else if (unit === "day") {
    date.hours(0).minutes(0).seconds(0).milliseconds();
  } else if (unit === "hour") {
    date.minutes(0).seconds(0).milliseconds();
  } else if (unit === "minute") {
    date.seconds(0).milliseconds();
  } else if (unit === "second") {
    date.milliseconds(0);
  } else {
    throw new Error("Unexpected time unit");
  }

  return date.toDate();
}

export const ScheduleManager: FC<Props> = ({ schedule, setSchedule, resource = "sync" }) => {
  const { data } = useDbtCredentialsQuery();
  const [dbtCredentials] = data?.dbt_credentials || [];

  const { isLoading: loadingAccounts, data: accountData } = useDbtAccountsQuery(
    {
      apiKey: dbtCredentials?.api_key ?? "",
      subdomain: dbtCredentials?.subdomain,
    },
    { enabled: Boolean(dbtCredentials?.api_key) },
  );

  const { isLoading: loadingJobs, data: jobData } = useDbtJobsQuery(
    {
      apiKey: dbtCredentials?.api_key ?? "",
      accountId: schedule?.schedule?.account?.id ?? "",
      subdomain: dbtCredentials?.subdomain,
    },
    { enabled: Boolean(dbtCredentials?.api_key && schedule?.schedule?.account?.id) },
  );

  const accounts = accountData?.getDBTAccounts || [];
  const jobs = jobData?.getDBTJobs || [];
  const advancedCronEditor = schedule?.schedule?.state?.advancedCronEditor;

  const [cronExpressionErrorMessage, setCronExpressionErrorMessage] = useState<undefined | string>();

  const defaultTime = moment("12:00", "HH:mm").utc().format("HH:mm");

  useEffect(() => {
    if (validCronExpression(schedule?.schedule?.expression)) {
      setCronExpressionErrorMessage(undefined);
    } else {
      setCronExpressionErrorMessage("Invalid cron expression.");
    }
  }, [schedule?.schedule?.expression]);

  useEffect(() => {
    if (schedule?.type === "cron") {
      setSchedule({
        ...schedule,
        schedule: {
          ...schedule?.schedule,
          state: {
            ...schedule?.schedule?.state,
            advancedCronEditor,
          },
        },
      });
    }
  }, [advancedCronEditor]);

  useEffect(() => {
    if (schedule?.type === "cron") {
      if (
        !schedule?.schedule?.state?.time &&
        !schedule?.schedule?.state?.interval &&
        !schedule?.schedule?.state?.days &&
        !schedule?.schedule?.advancedCronEditor
      ) {
        const now = new Date();
        const expression = CronGenerator.everyDayAt(now.getHours(), now.getMinutes());

        setSchedule({
          ...schedule,
          schedule: {
            ...schedule?.schedule,
            expression,
            state: {
              time: schedule?.schedule?.state?.time || defaultTime,
              interval: "day",
              every: 1,
              days: { [WEEK_DAYS[now.getDay()]!.toLowerCase()]: true },
            },
          },
        });
      }
    } else if (schedule?.type === "visual_cron" && !schedule?.schedule?.expressions) {
      setSchedule({
        ...schedule,
        schedule: {
          expressions: [
            {
              days: {},
              time: defaultTime,
            },
          ],
        },
      });
    }
  }, [schedule?.type]);

  useEffect(() => {
    if (schedule?.type === "cron" && !schedule?.schedule?.state?.advancedCronEditor && !schedule?.schedule?.expression) {
      let expression: string | undefined;
      const time: string | undefined = schedule?.schedule?.state?.time;
      if (time) {
        const [hours, minutes] = time.split(":").map(Number);
        if (schedule?.schedule?.state?.interval === "day") {
          expression = CronGenerator.every(schedule?.schedule?.state?.every || 1).days(hours, minutes);
        } else {
          const days = Object.keys(schedule?.schedule?.state?.days ?? {});
          expression = CronGenerator.onSpecificDaysAt(days, hours!, minutes);
        }
      }

      setSchedule({
        ...schedule,
        schedule: {
          ...schedule?.schedule,
          expression,
        },
      });
    }
  }, [schedule?.schedule?.state]);

  return (
    <Grid gap={12} sx={{ width: "100%", "& > div:not(:last-child)": { pb: 12, borderBottom: "small" } }}>
      <Section title="Schedule type">
        <RadioGroup
          options={getScheduleOptions(resource)}
          value={schedule?.type}
          onChange={(type) => (type === schedule?.type ? undefined : setSchedule({ schedule: null, type }))}
        />
      </Section>
      {schedule?.type && schedule?.type !== "manual" && (
        <Section title="Schedule configuration">
          <Grid gap={8}>
            {schedule?.type === "interval" && (
              <Grid gap={2} sx={{ gridAutoFlow: "column", gridAutoColumns: "max-content", alignItems: "center" }}>
                <Text sx={{ fontWeight: "bold" }}>Every</Text>
                <Input
                  min="1"
                  sx={{ width: "120px" }}
                  type="number"
                  value={schedule?.schedule?.interval?.quantity ? String(schedule?.schedule?.interval?.quantity) : ""}
                  onChange={(value) =>
                    setSchedule({
                      ...schedule,
                      schedule: {
                        interval: {
                          ...schedule?.schedule?.interval,
                          quantity: value ? Number(value) : null,
                        },
                      },
                    })
                  }
                />
                <Select
                  options={intervalUnitOptions}
                  placeholder="Interval..."
                  value={
                    schedule?.schedule?.interval?.unit
                      ? intervalUnitOptions.find((s) => schedule?.schedule?.interval?.unit === s.value)
                      : null
                  }
                  width={120}
                  onChange={(selected) =>
                    setSchedule({
                      ...schedule,
                      schedule: {
                        interval: {
                          ...schedule?.schedule?.interval,
                          unit: selected.value,
                        },
                      },
                    })
                  }
                />
              </Grid>
            )}

            {schedule?.type === "dbt" && !dbtCredentials && (
              <Text>
                Please <Link to="/extensions/dbt-cloud/configuration">add your dbt credentials</Link> before continuing.
              </Text>
            )}

            {schedule?.type === "dbt" && dbtCredentials && (
              <>
                <Field label="Account">
                  <Select
                    isLoading={loadingAccounts}
                    options={accounts.map((account) => ({
                      label: account.name,
                      value: account,
                    }))}
                    placeholder="Account"
                    value={
                      schedule?.schedule?.account && {
                        label: schedule.schedule.account.name,
                        value: schedule.schedule.account,
                      }
                    }
                    width="300px"
                    onChange={(selected) => {
                      setSchedule({
                        ...schedule,
                        schedule: {
                          ...schedule?.schedule,
                          dbtCredentialId: dbtCredentials.id,
                          account: {
                            id: selected.value.id,
                            name: selected.value.name,
                          },
                        },
                      });
                    }}
                  />
                </Field>

                <Field label="Job">
                  <Select
                    isLoading={loadingJobs}
                    options={jobs.map((job) => ({
                      label: job.name,
                      value: job,
                    }))}
                    placeholder="Job"
                    value={
                      schedule?.schedule?.job && {
                        label: schedule.schedule.job.name,
                        value: schedule.schedule.job,
                      }
                    }
                    width="300px"
                    onChange={(selected) => {
                      setSchedule({
                        ...schedule,
                        schedule: {
                          ...schedule?.schedule,
                          dbtCredentialId: dbtCredentials.id,
                          job: {
                            id: selected.value.id,
                            name: selected.value.name,
                          },
                        },
                      });
                    }}
                  />
                </Field>
              </>
            )}

            {schedule?.type === "visual_cron" && (
              <>
                <Grid gap={8}>
                  {schedule?.schedule?.expressions?.length &&
                    schedule.schedule.expressions.map((_expression, index) => (
                      <VisualCronExpression key={index} index={index} schedule={schedule} setSchedule={setSchedule} />
                    ))}
                </Grid>

                <Permission>
                  <Button
                    label="Add recurrence"
                    size="small"
                    variant="secondary"
                    onClick={() => {
                      setSchedule({
                        ...schedule,
                        schedule: {
                          ...schedule?.schedule,
                          expressions: [...(schedule?.schedule?.expressions ?? []), { days: {}, time: defaultTime }],
                        },
                      });
                    }}
                  />
                </Permission>
              </>
            )}

            {schedule?.type === "cron" && (
              <Field error={cronExpressionErrorMessage} label="Cron expression">
                <Input
                  sx={{ width: "300px" }}
                  value={schedule?.schedule?.expression}
                  onChange={(expression) => {
                    setSchedule({
                      ...schedule,
                      schedule: {
                        ...schedule?.schedule,
                        expression,
                      },
                    });
                  }}
                />
              </Field>
            )}
            <Grid
              columns={schedule?.startDate || schedule?.endDate ? "repeat(2, max-content)" : "repeat(4, max-content)"}
              gap={2}
              sx={{ gridAutoFlow: "row", alignItems: "center" }}
            >
              <Row sx={{ justifyContent: "end" }}>
                <Text>This schedule will be applied effective</Text>
              </Row>
              <Row sx={{ flex: "0 1 0", justifyContent: "start" }}>
                <NewSelect
                  options={[
                    { key: "immediate", value: "immediate", label: "immediately" },
                    { key: "deferred", value: "deferred", label: "from" },
                  ]}
                  value={schedule?.startDate ? "deferred" : "immediate"}
                  onChange={(value) =>
                    setSchedule({
                      ...schedule,
                      startDate: value === "immediate" ? null : schedule?.startDate ?? nextInterval("day").toISOString(),
                    })
                  }
                />
              </Row>
              {schedule?.startDate && (
                <DateTimeSelect
                  sx={{ gridColumn: 2, mb: 4 }}
                  value={schedule?.startDate ? new Date(schedule?.startDate) : nextInterval("day")}
                  onChange={(value) => {
                    setSchedule({
                      ...schedule,
                      startDate: value.toISOString(),
                    });
                  }}
                />
              )}
              <Row sx={{ justifyContent: "end" }}>
                <Text>and will remain effective</Text>
              </Row>
              <Row sx={{ width: "min-content" }}>
                <NewSelect
                  options={[
                    { key: "indefinite", value: "indefinite", label: "indefinitely" },
                    { key: "finite", value: "finite", label: "until" },
                  ]}
                  value={schedule?.endDate ? "finite" : "indefinite"}
                  onChange={(value) =>
                    setSchedule({
                      ...schedule,
                      endDate: value === "indefinite" ? null : schedule?.endDate ?? nextInterval("week").toISOString(),
                    })
                  }
                />
              </Row>
              {schedule?.endDate && (
                <DateTimeSelect
                  sx={{ gridColumn: 2 }}
                  value={schedule?.endDate ? new Date(schedule?.endDate) : nextInterval("week")}
                  onChange={(value) => {
                    setSchedule({
                      ...schedule,
                      endDate: value.toISOString(),
                    });
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Section>
      )}
    </Grid>
  );
};
