import { FC } from "react";

import { Controller, FormProvider, useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications2";

import { ScheduleManager } from "src/components/schedule";
import { ScheduleType } from "src/components/schedule/types";
import { SequenceForm, FormState } from "src/components/sequences/sequence-form";
import { useCreateSequenceMutation, SyncSequenceMembersInsertInput } from "src/graphql";
import { Column } from "src/ui/box";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { Input } from "src/ui/input";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { useNavigate } from "src/utils/navigate";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

import { ResourceType, useResourceName } from "../../utils/slug";

export const CreateSequence: FC = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [step, setStep] = useWizardStepper(0);
  const { name, slug, setName, loading: loadingName } = useResourceName(ResourceType.Sequence);

  const { mutateAsync: createSequence, isLoading: creating } = useCreateSequenceMutation();

  const formMethods = useForm<FormState>({
    defaultValues: {
      members: [],
      schedule: { schedule: null, type: ScheduleType.MANUAL },
    },
  });
  const { handleSubmit, control, watch } = formMethods;
  const schedule = watch("schedule");
  const members = watch("members");

  const submit = async (data) => {
    const members = data.members.map(({ sync, abort_seq_on_failure, abort_seq_on_rejects }, index) => {
      const member: SyncSequenceMembersInsertInput = {
        sync_id: sync.id,
        reference_id: index + 1,
        abort_seq_on_failure,
        abort_seq_on_rejects,
        links: {
          predecessors: index > 0 ? [index] : [],
          successors: index !== data.members.length - 1 ? [index + 2] : [],
        },
      };

      return member;
    });

    const sequence = await createSequence({
      object: {
        slug,
        name,
        schedule: data.schedule?.type === "manual" ? null : data.schedule,
        members: { data: members },
      },
    });

    if (sequence.insert_sync_sequences_one) {
      addToast(`Sequence created!`, {
        appearance: "success",
      });

      navigate(`/sequences/${sequence.insert_sync_sequences_one.id}`);
    }
  };

  const steps: Step[] = [
    {
      title: "Configure sequence",
      disabled: !(members.length > 1),
      header: <Heading>Add syncs to your sequence</Heading>,
      render: () => (
        <FormProvider {...formMethods}>
          <SequenceForm />
        </FormProvider>
      ),
    },
    {
      title: "Finalize sequence",
      disabled: !name || !schedule || loadingName,
      submitting: creating,
      header: <Heading>Finalize settings for this sequence</Heading>,
      render: () => {
        return (
          <Column sx={{ gap: 8, maxWidth: "600px" }}>
            <Field description="Including details about the sequence's business purpose" label="Sequence name">
              <Input autoFocus value={name} onChange={(name) => setName(name)} />
            </Field>
            <Controller
              control={control}
              name="schedule"
              render={({ field }) => (
                <ScheduleManager resource={"sequence"} schedule={field.value} setSchedule={field.onChange} />
              )}
            />
          </Column>
        );
      },
    },
  ];

  return (
    <Wizard
      setStep={setStep}
      step={step}
      steps={steps}
      title="New sequence"
      onCancel={() => {
        navigate("/sequences");
      }}
      onSubmit={handleSubmit(submit)}
    />
  );
};
