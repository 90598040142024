import { FC, useState, useCallback, useMemo, useEffect } from "react";

import * as Sentry from "@sentry/browser";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isEmpty } from "lodash";
import pluralize from "pluralize";
import { useToasts } from "react-toast-notifications2";
import { Text, Flex, Box, Image } from "theme-ui";

import { DraftBadge } from "src/components/drafts/draft-badge";
import { DraftIcon } from "src/components/drafts/draft-icon";
import { Filters, getHasuaExpFromFilters, syncFilterDefinitions } from "src/components/filter";
import { CreateViewModal } from "src/components/filter/create-view";
import { Views } from "src/components/filter/views";
import { EditLabels } from "src/components/labels/edit-labels";
import { Labels } from "src/components/labels/labels";
import { Page } from "src/components/layout";
import { Permission } from "src/components/permission";
import placeholderDestination from "src/components/permission/destination.svg";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  MinimalSyncsQuery,
  ResourcePermissionGrant,
  SyncsBoolExp,
  SyncsOrderBy,
  SyncsQuery,
  SyncsWithLabelsQuery,
  useAddLabelsToSyncsMutation,
  useDeleteSyncsMutation,
  useDraftsQuery,
  useMinimalSyncsQuery,
  useSyncFiltersQuery,
  useSyncsQuery,
  useSyncsWithLabelsQuery,
  useUpdateSyncsMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { Avatar } from "src/ui/avatar";
import { ObjectBadge } from "src/ui/badge";
import { Row, Column } from "src/ui/box";
import { Button, DropdownButton } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { ChevronDownIcon, ExternalLinkIcon, InfoIcon, LabelIcon } from "src/ui/icons";
import { SearchInput } from "src/ui/input";
import { Link } from "src/ui/link";
import { Menu, MenuOption } from "src/ui/menu";
import { Modal } from "src/ui/modal";
import { Popout } from "src/ui/popout";
import { Table, Pagination, useTableConfig, TableColumn } from "src/ui/table";
import { useFiltering } from "src/ui/table/use-filtering";
import { useRowSelect } from "src/ui/table/use-row-select";
import { TextWithTooltip } from "src/ui/text";
import { Tooltip } from "src/ui/tooltip";
import { useDestinations } from "src/utils/destinations";
import { useIncrementalQuery } from "src/utils/incremental-query";
import { useNavigate } from "src/utils/navigate";
import { SyncStatusBadge } from "src/utils/syncs";
import { formatDate, formatDatetime } from "src/utils/time";
import { openUrl } from "src/utils/urls";

import { useLabels } from "../../components/labels/use-labels";

enum SortKeys {
  Status = "status",
  SegmentName = "segment.name",
  DestinationName = "destination.name",
  LastRun = "sync_requests_aggregate.max.created_at",
  CreatedAt = "created_at",
}

const getBulkDeleteSyncMessage = (error: Error): string => {
  return error.message.startsWith("Foreign key violation") && error.message.includes("sync_sequence")
    ? "One or more of the selected syncs cannot be deleted because they are used in sequences"
    : error.message;
};

const SyncsContent: FC = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [loading, setLoading] = useState<boolean>(true);
  const [createViewModalOpen, setCreateViewModalOpen] = useState(false);
  const [processingAction, setProcessingAction] = useState(false);
  const [addingLabels, setAddingLabels] = useState(false);
  const { appSyncMetadata } = useFlags();

  const { hasPermission: userCanDelete } = useHasPermission([{ resource: "source", grants: [ResourcePermissionGrant.Delete] }]);

  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<SyncsOrderBy>({
    defaultSortKey: "created_at",
    limit: 25,
    sortOptions: Object.values(SortKeys),
  });

  const {
    state: { creatingView, filters, selectedView, viewNotSaved, views, updatingView },
    actions: { createView, deleteView, resetViewFilters, selectView, updateCurrentView, updateFilters },
  } = useFiltering({ viewKey: "sync" });

  const hasuraFilters = useMemo(() => {
    const hasuraFilters: SyncsBoolExp = {
      ...getHasuaExpFromFilters(syncFilterDefinitions, filters),
    };

    if (search) {
      const searchFilters: SyncsBoolExp[] = [
        { segment: { name: { _ilike: `%${search}%` } } },
        { destination: { name: { _ilike: `%${search}%` } } },
        { destination: { type: { _ilike: `%${search}%` } } },
      ];

      return { _and: [hasuraFilters, { _or: searchFilters }] };
    }

    return hasuraFilters;
  }, [filters, search]);

  // used for filters
  const { data: syncFilterData } = useSyncFiltersQuery();

  const fullSyncQuery = (appSyncMetadata ? useSyncsWithLabelsQuery : useSyncsQuery)(
    {
      offset,
      limit,
      filters: hasuraFilters,
      orderBy,
    },
    {
      refetchInterval: 10000,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const incrementalSyncs = useIncrementalQuery<MinimalSyncsQuery, SyncsWithLabelsQuery | SyncsQuery>(
    useMinimalSyncsQuery({
      offset,
      limit,
      filters: hasuraFilters,
      orderBy,
    }),
    fullSyncQuery,
  );

  const { data: drafts } = useDraftsQuery({
    resourceType: "sync",
    status: "pending",
  });

  const { labels } = useLabels();

  const { mutateAsync: bulkDeleteSyncs, isLoading: loadingBulkDelete } = useDeleteSyncsMutation();
  const { mutateAsync: updateSyncs } = useUpdateSyncsMutation();
  const { mutateAsync: addLabels, isLoading: loadingAddLabels } = useAddLabelsToSyncsMutation();

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText = destinationOverageText + " To create a sync, upgrade your plan.";

  const bulkUpdateStatus = async (enabled: boolean) => {
    setProcessingAction(true);

    return await updateSyncs(
      {
        ids: selectedRows.map(String),
        object: {
          schedule_paused: !enabled,
        },
      },
      {
        onSuccess: () => {
          addToast(`Selected syncs were ${enabled ? "enabled" : "disabled"}`, {
            appearance: "success",
          });

          onRowSelect([]);
          setProcessingAction(false);
          setLoading(true);
        },
        onError: (error) => {
          addToast(error.message, { appearance: "error", autoDismiss: false });
          Sentry.captureException(error);
        },
      },
    );
  };

  const bulkAddLabels = async (labels: Record<string, string>) => {
    const labelCount = Object.keys(labels).length;

    try {
      await addLabels({ ids: selectedRows.map(String), labels });
      setAddingLabels(false);
      addToast(
        `Added ${labelCount} ${pluralize("label", labelCount)} to ${selectedRows.length} ${pluralize(
          "sync",
          selectedRows.length,
        )}`,
        {
          appearance: "success",
        },
      );

      onRowSelect([]);
    } catch (error) {
      addToast("Failed to update labels.", {
        appearance: "error",
      });
      Sentry.captureException(error);
    }
  };

  const bulkDelete = async () => {
    setProcessingAction(true);

    if (userCanDelete) {
      await bulkDeleteSyncs(
        { ids: selectedRows.map(String) },
        {
          onSuccess: () => {
            addToast(`Selected syncs deleted!`, {
              appearance: "success",
            });
            onRowSelect([]);
          },
          onError: (error) => {
            addToast(getBulkDeleteSyncMessage(error), { appearance: "error", autoDismiss: false });
            Sentry.captureException(error);
          },
        },
      );

      setConfirmingDelete(false);
    } else {
      addToast("Delete not allowed. Please check your permissions.");
    }

    setProcessingAction(false);
  };

  const {
    data: { definitions: destinationDefinitions },
    error: destinationsError,
  } = useDestinations();

  const syncs = incrementalSyncs.data?.syncs;
  const syncsCount = incrementalSyncs.data?.syncs_aggregate?.aggregate?.count ?? 0;

  const actions: MenuOption[] = [
    { label: "Add labels", onClick: () => setAddingLabels(true) },
    { label: "Disable", onClick: () => bulkUpdateStatus(false) },
    { label: "Enable", onClick: () => bulkUpdateStatus(true) },
  ];

  if (userCanDelete) {
    actions.push({
      label: "Delete",
      onClick: () => setConfirmingDelete(true),
      sx: { color: "red", ":hover::not(:disabled)": { backgroundColor: "reds.0" } },
    });
  }

  const columns = useMemo(
    (): TableColumn[] =>
      [
        {
          name: "Status",
          sortDirection: orderBy?.status,
          onClick: () => onSort(SortKeys.Status),
          min: "130px",
          max: "130px",
          cell: ({ id, status, sync_requests, draft: isInitialDraft }) => {
            if (isInitialDraft) {
              return <DraftBadge />;
            }

            const syncRequest = sync_requests?.[0];
            const request = syncRequest ? syncRequest : { status_computed: status };

            const draft = drafts?.drafts.find((d) => String(d.resource_id) === String(id));
            return (
              <Row sx={{ alignItems: "center" }}>
                <SyncStatusBadge request={request} status={status} />
                {draft && <DraftIcon draft={draft} sx={{ ml: 2 }} />}
              </Row>
            );
          },
        },
        {
          name: "Model",
          sortDirection: orderBy?.segment?.name,
          onClick: () => onSort(SortKeys.SegmentName),
          cell: ({ segment }) => (
            <TextWithTooltip disabled={!segment?.name} sx={{ fontWeight: "semi", maxWidth: "300px" }} text={segment?.name}>
              {segment?.name || "Private Model"}
            </TextWithTooltip>
          ),
        },
        {
          name: "Destination",
          sortDirection: orderBy?.destination?.name,
          onClick: () => onSort(SortKeys.DestinationName),
          cell: ({ destination, labels }) => {
            const definition = destinationDefinitions?.find((d) => d.type === destination?.type);

            return (
              <Tooltip disabled={definition && destination} text="This destination is only visible to some users">
                <Row sx={{ alignItems: "center" }}>
                  <Image
                    alt={definition?.name ?? "Private Destination"}
                    src={definition?.icon ?? placeholderDestination}
                    sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", flexShrink: 0, mr: 2 }}
                  />
                  <TextWithTooltip
                    disabled={!destination?.name && !definition?.name}
                    sx={{
                      fontWeight: "semi",
                      maxWidth: "300px",
                    }}
                    text={destination?.name || definition?.name}
                  >
                    {destination?.name || definition?.name || "Private Destination"}
                  </TextWithTooltip>
                  {labels &&
                    Object.keys(labels).map((key) => (
                      <ObjectBadge key={key} sx={{ ml: 2 }}>
                        {labels[key]}
                      </ObjectBadge>
                    ))}
                </Row>
              </Tooltip>
            );
          },
        },
        {
          name: "Last run",
          sortDirection: orderBy?.last_run_at,
          onClick: () => onSort(SortKeys.LastRun),
          max: "200px",
          cell: ({ id, last_run_at, sync_requests }) => {
            return (
              <Flex sx={{ alignItems: "center" }}>
                {last_run_at && (
                  <>
                    <Text sx={{ mr: 2, fontWeight: "semi" }}>{formatDatetime(last_run_at)}</Text>
                    <Link to={sync_requests && `/syncs/${id}/runs/${sync_requests[0]?.id}`}>
                      <Box sx={{ color: "base.4", ":hover": { color: "secondary" } }}>
                        <ExternalLinkIcon size={14} />
                      </Box>
                    </Link>
                  </>
                )}
              </Flex>
            );
          },
        },
        {
          name: "Created At",
          max: "max-content",
          sortDirection: orderBy?.created_at,
          onClick: () => onSort(SortKeys.CreatedAt),
          cell: ({ created_at: timestamp, created_by_user }) => {
            const name = created_by_user?.name;

            if (!name && !timestamp) {
              return <Text sx={{ fontWeight: "semi" }}>-</Text>;
            }

            if (!name) {
              return <Text sx={{ fontWeight: "semi" }}>{formatDate(timestamp)}</Text>;
            }

            return (
              <Row sx={{ alignItems: "center" }}>
                <Text sx={{ color: "base.6", fontWeight: "semi" }}>{formatDate(timestamp)}</Text>
                <Text sx={{ color: "base.6", mr: 1 }}>&nbsp;by</Text>
                <Avatar name={name} />
              </Row>
            );
          },
        },
        {
          key: "tags",
          cell: (tags) => {
            if (isEmpty(tags)) {
              return null;
            }

            return (
              <Popout
                content={() => <Labels labels={tags} sx={{ maxWidth: "200px" }} />}
                contentSx={{ p: 3, minWidth: "90px" }}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                <LabelIcon size={16} sx={{ ":hover": { svg: { fill: "primary" } } }} />
              </Popout>
            );
          },
        },
      ].filter(Boolean),
    [destinationDefinitions, orderBy, onSort, drafts],
  );

  const onRowClick = useCallback(({ id }, event) => openUrl(`/syncs/${id}`, navigate, event), [navigate]);

  useEffect(() => {
    setPage(0);
  }, [hasuraFilters]);

  useEffect(() => {
    onRowSelect([]);
  }, [page]);

  useEffect(() => {
    setLoading(true);
  }, [limit, offset, orderBy, hasuraFilters]);

  useEffect(() => {
    if (!incrementalSyncs.minimalQueryRefetching) {
      setLoading(false);
    }
  }, [incrementalSyncs.minimalQueryRefetching, syncs]);

  return (
    <>
      <Column sx={{ mb: 3, width: "100%" }}>
        <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 8 }}>
          <Row sx={{ alignItems: "center" }}>
            <Heading sx={{ mr: 2 }}>Syncs</Heading>
            <Views
              deletePermissionResource="sync"
              value={selectedView}
              views={views}
              onChange={selectView}
              onDelete={deleteView}
            />
            {viewNotSaved &&
              (selectedView === "Default view" ? (
                <Button
                  sx={{ ml: 2 }}
                  variant="purple"
                  onClick={() => {
                    setCreateViewModalOpen(true);
                  }}
                >
                  Save as
                </Button>
              ) : (
                <DropdownButton
                  loading={updatingView}
                  options={[
                    {
                      label: "Save as",
                      onClick: () => {
                        setCreateViewModalOpen(true);
                      },
                    },
                    {
                      label: "Reset changes",
                      onClick: () => {
                        resetViewFilters();
                      },
                    },
                  ]}
                  sx={{ ml: 2 }}
                  onClick={updateCurrentView}
                >
                  Save
                </DropdownButton>
              ))}
          </Row>
          <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
            <Button
              disabled={overageLockout}
              tooltip={overageLockout ? overageText : undefined}
              onClick={() => {
                analytics.track("Add Sync Clicked");
                navigate(`/syncs/new`);
              }}
            >
              Add sync
            </Button>
          </Permission>
        </Row>
        <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
            <SearchInput placeholder={`Search syncs by model or destination...`} value={search ?? ""} onChange={setSearch} />

            <Filters
              data={syncFilterData?.syncs ?? []}
              filterDefinitions={syncFilterDefinitions}
              filters={filters}
              resourceType="sync"
              sx={{ ml: 2 }}
              onChange={updateFilters}
            />
          </Box>

          {selectedRows.length > 0 && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Text as="label" sx={{ display: "flex", alignItems: "center" }}>
                <Text as="span" sx={{ color: "base.5" }}>{`${pluralize("sync", selectedRows.length, true)} selected`}</Text>

                <Menu options={actions}>
                  <Button
                    propagate
                    iconAfter={<ChevronDownIcon size={16} />}
                    loading={processingAction}
                    sx={{ ml: 3 }}
                    variant="secondary"
                  >
                    Select action
                  </Button>
                </Menu>
              </Text>
            </Box>
          )}
        </Row>
      </Column>
      <Table
        columns={columns}
        data={syncs}
        error={Boolean(incrementalSyncs.fullQueryError || incrementalSyncs.minimalQueryError) || Boolean(destinationsError)}
        loading={incrementalSyncs.minimalQueryLoading || (loading && incrementalSyncs.minimalQueryRefetching)}
        placeholder={tablePlaceholder}
        selectedRows={selectedRows}
        onRowClick={onRowClick}
        onSelect={onRowSelect}
      />
      <Pagination count={syncsCount} label="syncs" page={page} rowsPerPage={limit} setPage={setPage} />

      <Modal
        bodySx={{ borderRadius: 2, pb: 5 }}
        footer={
          <>
            <Button variant="secondary" onClick={() => setConfirmingDelete(false)}>
              Cancel
            </Button>
            <Button loading={loadingBulkDelete} variant="red" onClick={bulkDelete}>
              Delete
            </Button>
          </>
        }
        header={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <InfoIcon sx={{ color: "red", mr: 3 }} />
            <Heading>
              Delete {pluralize("this", selectedRows.length, false)} {pluralize("Sync", selectedRows.length, false)}?
            </Heading>
          </Box>
        }
        isOpen={confirmingDelete}
        sx={{ borderRadius: 0, width: "600px" }}
        onClose={() => setConfirmingDelete(false)}
      >
        You will lose your sync {pluralize("configuration", selectedRows.length, false)}.
      </Modal>

      <CreateViewModal
        isOpen={createViewModalOpen}
        loading={creatingView}
        onClose={() => setCreateViewModalOpen(false)}
        onSave={createView}
      />

      <EditLabels
        description="You can label syncs that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={addingLabels}
        loading={loadingAddLabels}
        saveLabel={`Apply to ${selectedRows.length} ${pluralize("sync", selectedRows.length)}`}
        title="Add labels"
        onClose={() => setAddingLabels(false)}
        onSave={bulkAddLabels}
      />
    </>
  );
};

export const Syncs: FC = () => (
  <Page crumbs={[{ label: "Syncs", link: "/syncs" }]} size="full">
    <PermissionProvider permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
      <SyncsContent />
    </PermissionProvider>
  </Page>
);

const tablePlaceholder = {
  title: "No syncs",
  body: "Add a sync to get started",
  error: "Syncs failed to load, please try again.",
};
